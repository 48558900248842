const ERROR = {
  SYSTEM_ERROR: "System error. Please try again later!",
};
const PATH = {
  //  AUTH PATHS
  // AUTH_LOGIN: "/",
  LOGIN: "/",
  DASHBOARD: "/dashboard",
  USER_DASHBOARD: "/user-dashboard",
  USER: "/user",
  USER_PROFILE: "user-profile",
  EDIT_USER: "edit-user",
  GENERATE_BLOG: "/generate-blog",
  BLOG_REQUEST: "/blog-request",
  USER_BLOG_REQUEST: "/user-blog-request",
  MY_BLOG: "/my-blog",
  NO_PAGE_FOUND: "*",
};

export { ERROR, PATH };
