import axios from "axios";
import APP_SETTINGS from "../../config";

export function loginUserApi(data) {
  return axios.post(APP_SETTINGS.API_PATH.userLogin, data);
}
export const getAllUserApi = (userTypeID) => {
  return axios.get(
    `${APP_SETTINGS.API_PATH.getAllUsers}?typeId=${userTypeID?.typeId}&pageNo=${userTypeID.pageNo}&pageSize=${userTypeID.pageSize}&searchKey=${userTypeID.searchKey}`
  );
};
export const getAllUserStatsApi = (token) => {
  return axios.get(APP_SETTINGS.API_PATH.getUserStats);
};
export const getAllStatsApi = (data, token) => {
  return axios.get(`${APP_SETTINGS.API_PATH.getStats}/${data}`);
};
export const addUserApi = (data) => {
  return axios.post(APP_SETTINGS.API_PATH.addUser, data);
};
export const editUserApi = (data) => {
  return axios.put(`${APP_SETTINGS.API_PATH.editUser}${data?.userId}`, data);
};
export const archiveUserApi = (data) => {
  return axios.post(APP_SETTINGS.API_PATH.archiveUser, data);
};
export const archiveBlogrApi = (data) => {
  return axios.post(
    `${APP_SETTINGS.API_PATH.archiveBlog}?userID=${data?.userId}&requestId=${data?.requestId}`,
    data
  );
};
export const unArchiveBlogrApi = (data) => {
  return axios.post(
    `${APP_SETTINGS.API_PATH.unArchiveBlog}?userID=${data?.userId}&requestId=${data?.requestId}`,
    data
  );
};
export const deleteUserApi = (data) => {
  return axios.delete(`${APP_SETTINGS.API_PATH.deleteUser}/${data}`);
};

export const getAllUserBlogsApi = (data) => {
  return axios.get(
    `${APP_SETTINGS.API_PATH.user_blog}?userId=${data?.userId}&type=${data?.type}&pageNo=${data.pageNo}&pageSize=${data.pageSize}&searchKey=${data.searchKey}`
  );
};
export const getUserDetailsApi = (data) => {
  return axios.get(
    `${APP_SETTINGS.API_PATH.getUserDetails}?pageNo=${data?.pageNo}&pageSize=${data?.pageSize}&userId=${data?.userId}&typeID=${data?.typeID}&searchKey=${data?.searchKey}`
  );
};
export const deleteBlogApi = (data) => {
  return axios.delete(
    `${APP_SETTINGS.API_PATH.deleteBlog}/?userID=${data?.userID}&blogID=${data?.blogID}`
  );
};
export const latestBlogsApi = (data) => {
  return axios.get(
    `${APP_SETTINGS.API_PATH.latestBlogs}?pagesize=${data?.pagesize}&pagenumber=${data?.pagenumber}&searchKey=${data?.searchKey}`
  );
};
export const withoutfilterApi = (data) => {
  return axios.get(
    `${APP_SETTINGS.API_PATH.withoutfilter}?pagesize=${data?.pagesize}&pagenumber=${data?.pagenumber}&searchKey=${data?.searchKey}`
  );
};
export const TotalRecordsApi = () => {
  return axios.get(`${APP_SETTINGS.API_PATH.TotalRecords}`);
};
export const monthlyUsersApi = (data) => {
  return axios.get(`${APP_SETTINGS.API_PATH.monthlyUsers}/${data?.year}`);
};
export const blogPostApi = (data) => {
  return axios.post(APP_SETTINGS.API_PATH.blogPost, data);
};
export const monthlyBlogsApi = (data) => {
  return axios.get(`${APP_SETTINGS.API_PATH.monthlyBlogs}/${data?.year}`);
};
export const undoBlogApi = (data) => {
  return axios.post(
    `${APP_SETTINGS.API_PATH.undoBlog}?isUndo=${data?.isUndo}&createdID=${data?.createdID}`
  );
};
export const pushToClientApi = (data) => {
  if (data?.blogId === 0) {
    return axios.post(
      `${APP_SETTINGS.API_PATH.pushToClient}?userId=${data?.userId}&blogId=${data?.blogId}&requestid=${data?.requestid}&blog=${data?.blog}`
    );
  } else {
    return axios.post(
      `${APP_SETTINGS.API_PATH.pushToClient}?userId=${data?.userId}&blogId=${data?.blogId}`
    );
  }
};

export const blogtodraftApi = (data) => {
  return axios.post(
    `${APP_SETTINGS.API_PATH.blogtodraft}?userId=${data?.userId}`
  );
};

export const getblogtitleApi = (userId) => {
  return axios.get(`${APP_SETTINGS.API_PATH.getblogtitle}?userId=${userId}`);
};
export const regeneratetitleApi = (data) => {
  return axios.post(
    `${APP_SETTINGS.API_PATH.regeneratetitle}?bloghistid=${data?.bloghistid}`
  );
};
export const getDraftBlogApi = (data) => {
  return axios.get(
    `${APP_SETTINGS.API_PATH.getDraftBlog}?blogId=${data?.blogId}`
  );
};
export const getBlogHistoryApi = (data) => {
  return axios.get(
    `${APP_SETTINGS.API_PATH.getBlogHistory}?userId=${data?.userId}&requestId=${data?.requestId}`
  );
};
export const updateBlogApi = (data) => {
  return axios.post(
    `${APP_SETTINGS.API_PATH.updateBlog}?desc=${data?.desc}&blogId=${data?.blogId}`
  );
};
export const getUserInfoApi = (data) => {
  return axios.get(`${APP_SETTINGS.API_PATH.userInfo}/${data}`);
};
export const updateTitleApi = (data) => {
  return axios.post(
    `${APP_SETTINGS.API_PATH.updateTitle}?blogid=${data?.blogid}&title=${data?.title}`
  );
};
export const deleterequestApi = (data) => {
  return axios.delete(
    `${APP_SETTINGS.API_PATH.deleterequest}?requestId=${data?.requestId}`
  );
};
export const requestUnarchiveApi = (data) => {
  return axios.post(
    `${APP_SETTINGS.API_PATH.requestUnarchive}?userID=${data?.userID}&requestId=${data?.requestId}`
  );
};
export const userUnarchiveApi = (data) => {
  return axios.post(`${APP_SETTINGS.API_PATH.userUnarchive}`, data);
};
export const getallnotificationsApi = (data) => {
  return axios.get(
    `${APP_SETTINGS.API_PATH.getallnotifications}/${data?.userid}`
  );
};

export const getUserFilterApi = (data) => {
  return axios.get(
    `${APP_SETTINGS.API_PATH.getUserFilter}?prev=${data?.prev}&curr=${data?.curr}`
  );
};

export const getBlogDataFilterApi = (data) => {
  return axios.get(
    `${APP_SETTINGS.API_PATH.getBlogDataFilter}?prev=${data?.prev}&curr=${data?.curr}`
  );
};
export const changeFirstTimeVisitApi = (data) => {
  return axios.get(
    `${APP_SETTINGS.API_PATH.changeFirstTimeVisit}?userId=${data?.userId}&type=${data?.type}`
  );
};
