import { axiosInterceptors } from "../../AxiosInterceptor";
import { Notification } from "../../components/genericActions";
import {
  addUserApi,
  archiveUserApi,
  deleteBlogApi,
  deleteUserApi,
  //   deleteUserApi,
  editUserApi,
  getAllUserApi,
  getAllUserBlogsApi,
  getAllUserStatsApi,
  getUserDetailsApi,
  archiveBlogrApi,
  unArchiveBlogrApi,
  loginUserApi,
  monthlyBlogsApi,
  TotalRecordsApi,
  latestBlogsApi,
  monthlyUsersApi,
  getAllStatsApi,
  blogPostApi,
  undoBlogApi,
  pushToClientApi,
  getblogtitleApi,
  regeneratetitleApi,
  blogtodraftApi,
  getDraftBlogApi,
  getBlogHistoryApi,
  updateBlogApi,
  getUserInfoApi,
  updateTitleApi,
  deleterequestApi,
  getUserFilterApi,
  getBlogDataFilterApi,
  requestUnarchiveApi,
  userUnarchiveApi,
  getallnotificationsApi,
  changeFirstTimeVisitApi,
  withoutfilterApi,
} from "../api/userApi";
import { BLOGS, failure, request, success, USER } from "./utilities";
export const LoginUserAction = (data, onSuccess, Notification) => {
  return (dispatch) => {
    dispatch(request(USER.LOGIN_USER_LOADING));
    loginUserApi(data).then(
      (response) => {
        if (response.data.httpStatusCode === 200) {
          dispatch(success(USER.LOGIN_USER_SUCCESS, response.data.data));
          localStorage.setItem("hea_user_token", response.data.token);
          axiosInterceptors(response?.data?.token);
          if (onSuccess) {
            onSuccess(response.data.data);
          }
          if (Notification) {
            Notification(response.data.message, "success");
          }
        } else {
          dispatch(failure(USER.LOGIN_USER_FAILURE, response.data.message));
          if (Notification) {
            Notification(response.data.message, "error");
          }
        }
      },
      (error) => {
        dispatch(
          failure(
            USER.LOGIN_USER_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? Notification(error.response.data.message, "erroor")
              : Notification(error.message, "error")
          )
        );
      }
    );
  };
};
export const getAllUserAction = (userTypeID) => {
  return (dispatch) => {
    dispatch(request(USER.GET_USER_LOADING));
    getAllUserApi(userTypeID).then(
      (response) => {
        if (response.status === 200) {
          dispatch(success(USER.GET_USER_SUCCESS, response.data.data));
        } else {
          dispatch(failure(USER.GET_USER_FAILURE, response.data.message));
        }
      },
      (error) => {
        dispatch(
          failure(
            USER.GET_USER_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
};

export const getAllUserStats = () => {
  return (dispatch) => {
    dispatch(request(USER.GET_USER_STATS_LOADING));
    getAllUserStatsApi().then(
      (response) => {
        if (response.status === 200) {
          dispatch(success(USER.GET_USER_STATS_SUCCESS, response.data.data));
        } else {
          dispatch(failure(USER.GET_USER_STATS_FAILURE, response.data.message));
        }
      },
      (error) => {
        dispatch(
          failure(
            USER.GET_USER_STATS_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
};

export const getAllStats = (data) => {
  return (dispatch) => {
    dispatch(request(USER.GET_STATS_LOADING));
    getAllStatsApi(data).then(
      (response) => {
        if (response.status === 200) {
          dispatch(success(USER.GET_STATS_SUCCESS, response.data.data));
        } else {
          dispatch(failure(USER.GET_STATS_FAILURE, response.data.message));
        }
      },
      (error) => {
        dispatch(
          failure(
            USER.GET_STATS_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
};

export const addUserAction = (data, onSuccess, Notification) => {
  return (dispatch) => {
    dispatch(request(USER.ADD_USER_LOADING));
    addUserApi(data).then(
      (response) => {
        if (response.data.httpStatusCode === 201) {
          dispatch(success(USER.ADD_USER_SUCCESS, response.data));
          if (onSuccess) {
            onSuccess();
          }
          if (Notification) {
            Notification(response.data.message, "success");
          }
        } else {
          dispatch(failure(USER.ADD_USER_FAILURE, response.data.message));
          if (Notification) {
            Notification(response.data.message, "error");
          }
        }
      },
      (error) => {
        dispatch(
          failure(
            USER.ADD_USER_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.detail[0] &&
              error.response.data.detail[0].msg
              ? Notification(error.response.data.detail[0].msg, "error")
              : Notification(error.message, "error")
          )
        );
      }
    );
  };
};

export const editUserAction = (data, onSuccess, Notification) => {
  return (dispatch) => {
    dispatch(request(USER.EDIT_USER_LOADING));
    editUserApi(data).then(
      (response) => {
        if (response.status === 200) {
          dispatch(success(USER.EDIT_USER_SUCCESS, response.data));
          if (onSuccess) {
            onSuccess();
          }
          if (Notification) {
            Notification(response.data.message, "success");
          }
        } else {
          dispatch(failure(USER.GET_USER_FAILURE, response.data.message));
        }
      },
      (error) => {
        dispatch(
          failure(
            USER.EDIT_USER_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? Notification(error.response.data.message, "error")
              : Notification(error.message, "error")
          )
        );
      }
    );
  };
};

export const archiveUserAction = (data, onSuccess, Notification) => {
  return (dispatch) => {
    dispatch(request(USER.ARCHIVE_USER_LOADING));
    archiveUserApi(data).then(
      (response) => {
        if (response.status === 200) {
          dispatch(success(USER.ARCHIVE_USER_SUCCESS, response.data));
          if (onSuccess) {
            onSuccess();
          }
          if (Notification) {
            Notification(response.data.message, "success");
          }
        } else {
          dispatch(failure(USER.ARCHIVE_USER_FAILURE, response.data.message));
        }
      },
      (error) => {
        dispatch(
          failure(
            USER.ARCHIVE_USER_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? Notification(error.response.data.message, "error")
              : Notification(error.message, "error")
          )
        );
      }
    );
  };
};
export const unArchiveBlogAction = (data, onSuccess, Notification) => {
  return (dispatch) => {
    dispatch(request(USER.UNARCHIVE_BLOGREQUEST_FAILURE));
    unArchiveBlogrApi(data).then(
      (response) => {
        if (response.status === 200) {
          dispatch(success(USER.UNARCHIVE_BLOGREQUEST_SUCCESS, response.data));
          if (onSuccess) {
            onSuccess();
          }
          if (Notification) {
            Notification(response.data.message, "success");
          }
        } else {
          dispatch(
            failure(USER.UNARCHIVE_BLOGREQUEST_FAILURE, response.data.message)
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            USER.UNARCHIVE_BLOGREQUEST_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? Notification(error.response.data.message, "error")
              : Notification(error.message, "error")
          )
        );
      }
    );
  };
};
export const archiveBlogAction = (data, onSuccess, Notification) => {
  return (dispatch) => {
    dispatch(request(USER.ARCHIVE_BLOGREQUEST_LOADING));
    archiveBlogrApi(data).then(
      (response) => {
        if (response.status === 200) {
          dispatch(success(USER.ARCHIVE_BLOGREQUEST_SUCCESS, response.data));
          if (onSuccess) {
            onSuccess();
          }
          if (Notification) {
            Notification(response.data.message, "success");
          }
        } else {
          dispatch(
            failure(USER.ARCHIVE_BLOGREQUEST_FAILURE, response.data.message)
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            USER.ARCHIVE_BLOGREQUEST_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? Notification(error.response.data.message, "error")
              : Notification(error.message, "error")
          )
        );
      }
    );
  };
};

export const deleteUserAction = (data, onSuccess, Notification) => {
  return (dispatch) => {
    dispatch(request(USER.DELETE_USER_LOADING));
    // dispatch(success(USER.DELETE_USER_SUCCESS, data));
    deleteUserApi(data).then(
      (response) => {
        if (data) {
          dispatch(success(USER.DELETE_USER_SUCCESS, response.data));
          if (onSuccess) {
            onSuccess();
          }
          if (Notification) {
            Notification(response.data.message, "success");
          }
        } else {
          dispatch(failure(USER.DELETE_USER_FAILURE, response.data.message));
        }
      },
      (error) => {
        dispatch(
          failure(
            USER.DELETE_USER_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? Notification(error.response.data.message, "error")
              : Notification(error.message, "error")
          )
        );
      }
    );
  };
};
export const getAllUserBlogs = (data) => {
  return (dispatch) => {
    dispatch(request(BLOGS.GET_USER_BLOGS_LOADING));
    getAllUserBlogsApi(data).then(
      (response) => {
        if (response.status === 200) {
          dispatch(success(BLOGS.GET_USER_BLOGS_SUCCESS, response.data.data));
        } else {
          dispatch(
            failure(BLOGS.GET_USER_BLOGS_FAILURE, response.data.message)
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            BLOGS.GET_USER_BLOGS_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
};

export const getUserDetailsAction = (data) => {
  return (dispatch) => {
    dispatch(request(USER.GET_USER_DETAILS_LOADING));
    // dispatch(success(USER.GET_USER_DETAILS_SUCCESS, data));
    getUserDetailsApi(data).then(
      (response) => {
        if (data) {
          dispatch(success(USER.GET_USER_DETAILS_SUCCESS, response.data));
        } else {
          dispatch(
            failure(USER.GET_USER_DETAILS_FAILURE, response.data.message)
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            USER.GET_USER_DETAILS_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
};
export const monthlyBlogsAction = (data) => {
  return (dispatch) => {
    dispatch(request(USER.GET_MONTHLY_BLOGS_LOADING));
    monthlyBlogsApi(data).then(
      (response) => {
        if (response.status === 200) {
          dispatch(success(USER.GET_MONTHLY_BLOGS_SUCCESS, response.data.data));
        } else {
          dispatch(
            failure(USER.GET_MONTHLY_BLOGS_FAILURE, response.data.message)
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            USER.GET_MONTHLY_BLOGS_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
};
export const monthlyUsersAction = (data) => {
  return (dispatch) => {
    dispatch(request(USER.GET_MONTHLY_USERS_LOADING));
    monthlyUsersApi(data).then(
      (response) => {
        if (response.status === 200) {
          dispatch(success(USER.GET_MONTHLY_USERS_SUCCESS, response.data.data));
        } else {
          dispatch(
            failure(USER.GET_MONTHLY_USERS_FAILURE, response.data.message)
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            USER.GET_MONTHLY_USERS_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
};
export const TotalRecordsAction = () => {
  return (dispatch) => {
    dispatch(request(USER.GET_ALL_USERS_BLOGS_COUNTS_LOADING));
    TotalRecordsApi().then(
      (response) => {
        if (response.status === 200) {
          dispatch(
            success(USER.GET_ALL_USERS_BLOGS_COUNTS_SUCCESS, response.data.data)
          );
        } else {
          dispatch(
            failure(
              USER.GET_ALL_USERS_BLOGS_COUNTS_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            USER.GET_ALL_USERS_BLOGS_COUNTS_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
};
export const getAllBlogsWithoutfilterAction = (data) => {
  return (dispatch) => {
    dispatch(request(USER.GET_BLOGS_WITHOUTFILTER_LOADING));
    withoutfilterApi(data).then(
      (response) => {
        if (response.status === 200) {
          dispatch(
            success(USER.GET_BLOGS_WITHOUTFILTER_SUCCESS, response.data.data)
          );
        } else {
          dispatch(
            failure(USER.GET_BLOGS_WITHOUTFILTER_FAILURE, response.data.message)
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            USER.GET_BLOGS_WITHOUTFILTER_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
};
export const latestBlogsAction = (data) => {
  return (dispatch) => {
    dispatch(request(USER.GET_RECENT_BLOGS_LOADING));
    latestBlogsApi(data).then(
      (response) => {
        if (response.status === 200) {
          dispatch(success(USER.GET_RECENT_BLOGS_SUCCESS, response.data.data));
        } else {
          dispatch(
            failure(USER.GET_RECENT_BLOGS_FAILURE, response.data.message)
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            USER.GET_RECENT_BLOGS_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
};
export const deleteBlogAction = (data, onSuccess, Notification) => {
  return (dispatch) => {
    dispatch(request(BLOGS.DELETE_BLOGS_LOADING));
    // dispatch(success(USER.DELETE_USER_SUCCESS, data));
    deleteBlogApi(data).then(
      (response) => {
        if (data) {
          dispatch(success(BLOGS.DELETE_BLOGS_SUCCESS, response.data));
          if (onSuccess) {
            onSuccess();
          }
          if (Notification) {
            Notification(response.data.message, "success");
          }
        } else {
          dispatch(failure(BLOGS.DELETE_BLOGS_FAILURE, response.data.message));
        }
      },
      (error) => {
        dispatch(
          failure(
            BLOGS.DELETE_BLOGS_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? Notification(error.response.data.message, "error")
              : Notification(error.message, "error")
          )
        );
      }
    );
  };
};
export const blogPostAction = (data, Notification) => {
  return (dispatch) => {
    dispatch(request(USER.POST_BLOGS_LOADING));
    blogPostApi(data).then(
      (response) => {
        if (response.data.httpStatusCode === 201) {
          dispatch(success(USER.POST_BLOGS_SUCCESS, response.data.data));
          if (Notification) {
            Notification("Blog Generated Successfully", "success");
          }
        } else {
          dispatch(failure(USER.POST_BLOGS_FAILURE, response.data.message));
          if (Notification) {
            Notification(
              "We are experiencing high traffic in openAI and are working to resolve the issue as soon as possible. Please try again later.",
              "error"
            );
          }
        }
      },
      (error) => {
        dispatch(
          failure(
            USER.POST_BLOGS_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? Notification(error.response.data.message, "error")
              : Notification(error.message, "error")
          )
        );
      }
    );
  };
};
export const undoBlogAction = (data, onSuccess) => {
  return (dispatch) => {
    dispatch(request(USER.UNDO_BLOGS_LOADING));
    undoBlogApi(data).then(
      (response) => {
        if (response.data.httpStatusCode === 200) {
          dispatch(success(USER.UNDO_BLOGS_SUCCESS, response.data.data));
          if (onSuccess) {
            onSuccess(response?.data?.data[0]);
          }
        } else {
          dispatch(failure(USER.UNDO_BLOGS_FAILURE, response.data.message));
        }
      },
      (error) => {
        dispatch(
          failure(
            USER.UNDO_BLOGS_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
};
export const pushTOClientAction = (data, Notification, onPushToClient) => {
  return (dispatch) => {
    dispatch(request(USER.PUSH_TO_CLIENT_LOADING));
    pushToClientApi(data).then(
      (response) => {
        if (response.data.httpStatusCode === 200) {
          dispatch(success(USER.PUSH_TO_CLIENT_SUCCESS, response.data.data));
          if (Notification) {
            Notification("Data is pushed", "success");
          }
          if (onPushToClient) {
            onPushToClient();
          }
        } else {
          dispatch(failure(USER.PUSH_TO_CLIENT_FAILURE, response.data.message));
        }
      },
      (error) => {
        dispatch(
          failure(
            USER.PUSH_TO_CLIENT_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
};
export const blogtodraftAction = (data, Notification, onSuccessData) => {
  return (dispatch) => {
    dispatch(request(USER.SAVE_AS_DRAFT_LOADING));
    blogtodraftApi(data).then(
      (response) => {
        if (response.data.httpStatusCode === 200) {
          dispatch(success(USER.SAVE_AS_DRAFT_SUCCESS, response.data.data));
          if (Notification) {
            Notification("Blog save to draft", "success");
          }
          if (onSuccessData) {
            onSuccessData();
          }
        } else {
          dispatch(failure(USER.SAVE_AS_DRAFT_FAILURE, response.data.message));
        }
      },
      (error) => {
        dispatch(
          failure(
            USER.SAVE_AS_DRAFT_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
};
export const getblogtitleAction = (userId) => {
  return (dispatch) => {
    dispatch(request(USER.GET_TITLE_LOADING));
    getblogtitleApi(userId).then(
      (response) => {
        if (response.data.httpStatusCode === 200) {
          dispatch(success(USER.GET_TITLE_SUCCESS, response.data.data));
        } else {
          dispatch(failure(USER.GET_TITLE_FAILURE, response.data.message));
        }
      },
      (error) => {
        dispatch(
          failure(
            USER.GET_TITLE_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
};
export const regeneratetitleAction = (data, successMethod, Notification) => {
  return (dispatch) => {
    dispatch(request(USER.REGENERATE_TITLE_LOADING));
    regeneratetitleApi(data).then(
      (response) => {
        if (response.data.httpStatusCode === 200) {
          dispatch(success(USER.REGENERATE_TITLE_SUCCESS, response.data.data));
          if (successMethod) {
            successMethod(response.data.data);
          }
          if (Notification) {
            Notification(response.data.message, "success");
          }
        } else {
          dispatch(
            failure(USER.REGENERATE_TITLE_FAILURE, response.data.message)
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            USER.REGENERATE_TITLE_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
};
export const getDraftBlogAction = (data, onSuccessMethod) => {
  return (dispatch) => {
    dispatch(request(USER.GET_DRAFT_BLOG_LOADING));
    getDraftBlogApi(data).then(
      (response) => {
        if (response.data.httpStatusCode === 200) {
          dispatch(success(USER.GET_DRAFT_BLOG_SUCCESS, response.data.data));
          if (onSuccessMethod) {
            onSuccessMethod(response.data.data[0]);
          }
        } else {
          dispatch(failure(USER.GET_DRAFT_BLOG_FAILURE, response.data.message));
        }
      },
      (error) => {
        dispatch(
          failure(
            USER.GET_DRAFT_BLOG_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
};
export const getBlogHistory = (data) => {
  return (dispatch) => {
    dispatch(request(USER.GET_BLOG_HISTORY_LOADING));
    getBlogHistoryApi(data).then(
      (response) => {
        if (response.data.httpStatusCode === 200) {
          dispatch(success(USER.GET_BLOG_HISTORY_SUCCESS, response.data.data));
        } else {
          dispatch(
            failure(USER.GET_BLOG_HISTORY_FAILURE, response.data.message)
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            USER.GET_BLOG_HISTORY_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
};
export const updateBlogAction = (data, successCallBack) => {
  return (dispatch) => {
    dispatch(request(USER.UPDATE_BLOG_LOADING));
    updateBlogApi(data).then(
      (response) => {
        if (response.data.httpStatusCode === 200) {
          dispatch(
            success(USER.UPDATE_BLOG_SUCCESS, {
              desc: response.data.data,
              blogId: data.blogId,
            })
          );
          successCallBack();
        } else {
          dispatch(failure(USER.UPDATE_BLOG_FAILURE, response.data.message));
        }
      },
      (error) => {
        dispatch(
          failure(
            USER.UPDATE_BLOG_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
};

export const getUserInfoAction = (data) => {
  return (dispatch) => {
    dispatch(request(USER.GET_USER_INFO_LOADING));
    getUserInfoApi(data).then(
      (response) => {
        if (response.status === 200) {
          dispatch(success(USER.GET_USER_INFO_SUCCESS, response.data.data));
        } else {
          dispatch(failure(USER.GET_USER_INFO_FAILURE, response.data.message));
        }
      },
      (error) => {
        dispatch(
          failure(
            USER.GET_USER_INFO_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
};
export const updateTitleAction = (data) => {
  return (dispatch) => {
    dispatch(request(USER.UPDATE_TITLE_LOADING));
    updateTitleApi(data).then(
      (response) => {
        if (response.data.httpStatusCode === 200) {
          dispatch(success(USER.UPDATE_TITLE_SUCCESS, response.data.data));
          if (Notification) {
            Notification("Title Updated", "success");
          }
        } else {
          dispatch(failure(USER.UPDATE_TITLE_FAILURE, response.data.message));
        }
      },
      (error) => {
        dispatch(
          failure(
            USER.UPDATE_TITLE_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? Notification(error.response.data.message, "error")
              : Notification(error.message, "error")
          )
        );
      }
    );
  };
};
export const deleterequestAction = (data, onSuccess, Notification) => {
  return (dispatch) => {
    dispatch(request(USER.DELETE_REQUEST_LOADING));
    deleterequestApi(data).then(
      (response) => {
        if (response.data.httpStatusCode === 200) {
          dispatch(success(USER.DELETE_REQUEST_SUCCESS, response.data.data));
          if (onSuccess) {
            onSuccess();
          }
          if (Notification) {
            Notification("Request Deleted", "success");
          }
        } else {
          dispatch(failure(USER.DELETE_REQUEST_FAILURE, response.data.message));
        }
      },
      (error) => {
        dispatch(
          failure(
            USER.DELETE_REQUEST_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? Notification(error.response.data.message, "error")
              : Notification(error.message, "error")
          )
        );
      }
    );
  };
};

export const getUserFilter = (data, onSuccess, Notification) => {
  return (dispatch) => {
    dispatch(request(USER.GET_USER_FILTER_LOADING));
    getUserFilterApi(data).then(
      (response) => {
        if (response.data.httpStatusCode === 200) {
          dispatch(success(USER.GET_USER_FILTER_SUCCESS, response.data.data));
          if (onSuccess) {
            onSuccess();
          }
          if (Notification) {
            Notification(response.data.message, "success");
          }
        } else {
          dispatch(
            failure(USER.GET_USER_FILTER_FAILURE, response.data.message)
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            USER.GET_USER_FILTER_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
};
export const getBlogDataFilter = (data, onSuccess, Notification) => {
  return (dispatch) => {
    dispatch(request(USER.GET_BLOG_DATA_FILTER_LOADING));
    getBlogDataFilterApi(data).then(
      (response) => {
        if (response.data.httpStatusCode === 200) {
          dispatch(
            success(USER.GET_BLOG_DATA_FILTER_SUCCESS, response.data.data)
          );
          if (onSuccess) {
            onSuccess();
          }
          if (Notification) {
            Notification("Monthly blogs get successfully", "success");
          }
        } else {
          dispatch(
            failure(USER.GET_BLOG_DATA_FILTER_FAILURE, response.data.message)
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            USER.GET_BLOG_DATA_FILTER_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
};
export const getallnotificationsAction = (
  data,
  Notification,
  onGetNotification
) => {
  return (dispatch) => {
    dispatch(request(USER.GET_ALL_NOTIFICATIONS_LOADING));
    getallnotificationsApi(data).then(
      (response) => {
        if (response.data.httpStatusCode === 200) {
          dispatch(
            success(USER.GET_ALL_NOTIFICATIONS_SUCCESS, response.data.data)
          );
          if (onGetNotification) {
            onGetNotification();
          }
        } else {
          dispatch(
            failure(USER.GET_ALL_NOTIFICATIONS_FAILURE, response.data.message)
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            USER.GET_ALL_NOTIFICATIONS_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? Notification(error.response.data.message, "error")
              : Notification(error.message, "error")
          )
        );
      }
    );
  };
};
export const userUnarchiveAction = (data, onSuccess, Notification) => {
  return (dispatch) => {
    dispatch(request(USER.USER_UNARCHIVE_LOADING));
    userUnarchiveApi(data).then(
      (response) => {
        if (response.data.httpStatusCode === 200) {
          dispatch(success(USER.USER_UNARCHIVE_SUCCESS, response.data.data));
          if (onSuccess) {
            onSuccess();
          }
          if (Notification) {
            Notification(response.data.message, "success");
          }
        } else {
          dispatch(failure(USER.USER_UNARCHIVE_FAILURE, response.data.message));
        }
      },
      (error) => {
        dispatch(
          failure(
            USER.USER_UNARCHIVE_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? Notification(error.response.data.message, "error")
              : Notification(error.message, "error")
          )
        );
      }
    );
  };
};
export const requestUnarchiveAction = (data, onSuccess, Notification) => {
  return (dispatch) => {
    dispatch(request(USER.REQUEST_UNARCHIVE_LOADING));
    requestUnarchiveApi(data).then(
      (response) => {
        if (response.data.httpStatusCode === 200) {
          dispatch(success(USER.REQUEST_UNARCHIVE_SUCCESS, response.data.data));
          if (onSuccess) {
            onSuccess();
          }
          if (Notification) {
            Notification(response.data.message, "success");
          }
        } else {
          dispatch(
            failure(USER.REQUEST_UNARCHIVE_FAILURE, response.data.message)
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            USER.REQUEST_UNARCHIVE_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? Notification(error.response.data.message, "error")
              : Notification(error.message, "error")
          )
        );
      }
    );
  };
};
export const changeFirstTimeVisitAction = (data, onSuccess, Notification) => {
  return (dispatch) => {
    dispatch(request(USER.FIRST_TIEM_VISIT_LOADING));
    changeFirstTimeVisitApi(data).then(
      (response) => {
        if (response.data.httpStatusCode === 200) {
          dispatch(success(USER.FIRST_TIEM_VISIT_SUCCESS, response.data.data));
          if (onSuccess) {
            onSuccess();
          }
          if (Notification) {
            Notification(response.data.message, "success");
          }
        } else {
          dispatch(
            failure(USER.FIRST_TIEM_VISIT_FAILURE, response.data.message)
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            USER.FIRST_TIEM_VISIT_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? Notification(error.response.data.message, "error")
              : Notification(error.message, "error")
          )
        );
      }
    );
  };
};
