/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import IMAGES from "../../assets/images";
import Dropdown from "react-bootstrap/Dropdown";
// import { FaBars } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import { PATH } from "../../utils/path";
// import { GoPrimitiveDot } from "react-icons/go";
// import { useDispatch } from "react-redux";
import { Badge, Breadcrumb, Button, Modal } from "react-bootstrap";
import { FaBars, FaSignOutAlt } from "react-icons/fa";
import { useCookies } from "react-cookie";
// import ArchiveModal from "../../pages/adminSide/generateBlogs/GenerateBlogModal.jsx";
import BlogRequestModal from "../genericModal/BlogRequestModal";
import GenerateBlogRequestModal from "../genericModal/GenerateBlogRequestModal";
import RequestSentModal from "../genericModal/RequestSentModal";
import RejectionBlogModal from "../genericModal/RejectionBlogModal";
import { useAuth } from "../../authorization/ProvidedAuth";
import GenerateBlogModal from "../../pages/adminSide/generateBlogs/GenerateBlogModal.jsx";
import ProfileUpdateModal from "../genericModal/ProfileUpdateModal";
import { AiFillSetting } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { getallnotificationsAction } from "../../redux/actions/userAction";
import { Notification } from "../genericActions";
import { USER, success } from "../../redux/actions/utilities";
import axios from "axios";
export function Header() {
  document.body.style.backgroundColor = "#f5f5f5";
  let location = useLocation();
  // let dispatch = useDispatch();
  let navigate = useNavigate();
  const [countState, setCountState] = useState(false);
  const [generateBlogModal, setGenerateBlogModal] = useState(false);
  const [requestBlogModal, setRequestBlogModal] = useState(false);
  const [GenerateRequestBlogModal, setGenerateRequestBlogModal] =
    useState(false);
  const [requestSentModal, setRequestSentModal] = useState(false);
  const [rejectionBlogModal, setRejectionBlogModal] = useState(false);
  const [profileModal, setProfileModal] = useState(false);
  let auth = useAuth();
  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookie, removeCookie] = useCookies(["hea_user"]);
  function requestBlog() {
    setGenerateRequestBlogModal(true);
  }

  // useEffect(() => {
  //   let req = {
  //     userid: user?.Id,
  //   };
  //   dispatch(getallnotificationsAction(req, Notification));
  // }, []);

  function GenerateRequestBlog() {
    setGenerateRequestBlogModal(false);
    setRequestSentModal(true);
  }
  const Logout = () => {
    removeCookie("hea_user", { path: "/" });
    localStorage?.removeItem("hea_user", { path: "/" });
    localStorage?.removeItem("hea_user_token");
    dispatch(success(USER.SET_SESSION_EXPIRE_STATE, false));
    window.localStorage.removeItem("heaTokenExpiry");
    navigate(PATH.LOGIN);
  };
  function onGenerateBlog() {
    navigate("/generate-blog");
  }
  const user = JSON?.parse(localStorage?.getItem("hea_user"));

  const UserNameTwoCharacter = user?.UserName?.slice(0, 2);
  let dispatch = useDispatch();
  const onGetNotification = () => {
    console.log("user?.TotalNotification", user?.TotalNotification);
    // localStorage.setItem(
    //   "hea_user",
    //   JSON.stringify({
    //     ...user,
    //     TotalNotification : user?.TotalNotification
    //   })
    // );
  };

  const onHandleNotification = () => {
    console.log("user?.TotalNotification", user?.TotalNotification);
    let req = {
      userid: user?.Id,
    };
    dispatch(getallnotificationsAction(req, Notification, onGetNotification));
  };
  let getNotificationData = useSelector((state) => state?.user);
  // const { sessionExpire } = useSelector((state) => state.user);
  // if(sessionExpire===true){
  //   localStorage?.removeItem("hea_user", { path: "/" });
  //   localStorage?.removeItem("hea_user_token");
  //   dispatch(success(USER.SET_SESSION_EXPIRE_STATE, false));
  //   window.localStorage.removeItem("heaTokenExpiry");
  //   navigate(PATH.LOGIN);
  // }
  async function postData() {
    axios
    // .delete(
    //   `https://searchfuelapp.healthcareboss.org/clearnotifications?userId=${user?.Id}`
    // )
      .delete(`https://healthcare-383sq.ondigitalocean.app/clearnotifications?userId=${user?.Id}`)
      .then((response) => {
        console.log(response.data);
        localStorage.setItem(
          "hea_user",
          JSON.stringify({
            ...user,
            TotalNotification: 0,
          })
        );
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function getNotificationCount() {
    axios
    .get(
      `https://healthcare-383sq.ondigitalocean.app/getallnotifications/persecond/${user?.Id}`
    )
      // .get(
      //   `https://searchfuelapp.healthcareboss.org/getallnotifications/persecond/${user?.Id}`
      // )
      .then((response) => {
        setCountState(response?.data?.total_Count);
        console.log(response.data.total_Count);
      })
      .catch((error) => {
        console.error(error);
      });
  }
  useEffect(() => {
    const timerId = setInterval(() => {
      getNotificationCount();
    }, 20000);
    return () => clearInterval(timerId);
  }, []);

  return (
    <>
      <div className="header">
        {/* Logo */}
        <div className="PageName">
          {/* Mobile Menu Toggle */}
          <a href="javascript:void(0)" className="mobile_btn" id="mobile_btn">
            <FaBars />
          </a>
          <>
            {location.pathname === PATH.DASHBOARD && (
              <div className="col-12 head-breadcrumb">
                <Breadcrumb className="header-breadcrumb">
                  <Breadcrumb.Item
                    active
                    className="breadcrum_Project"
                    href="#"
                  >
                    Dashboard
                  </Breadcrumb.Item>
                </Breadcrumb>
              </div>
            )}
            {location.pathname === PATH.USER_DASHBOARD && (
              <div className="col-12 head-breadcrumb">
                <Breadcrumb className="header-breadcrumb">
                  <Breadcrumb.Item
                    active
                    className="breadcrum_Project"
                    href="#"
                  >
                    Dashboard
                  </Breadcrumb.Item>
                </Breadcrumb>
              </div>
            )}
            {location.pathname === PATH.USER && (
              <div className="col-12">
                <Breadcrumb className="header-breadcrumb">
                  <Breadcrumb.Item
                    active
                    className="breadcrum_Project"
                    href="#"
                    onClick={() => navigate("/user")}
                  >
                    Users
                  </Breadcrumb.Item>
                </Breadcrumb>
              </div>
            )}
            {location.pathname.includes("/user-profile") && (
              <div className="col-12">
                <Breadcrumb className="header-breadcrumb">
                  <Breadcrumb.Item
                    className="breadcrum_Project"
                    href="#"
                    onClick={() => navigate("/user")}
                  >
                    User &gt;
                  </Breadcrumb.Item>
                  <Breadcrumb.Item
                    active
                    className="breadcrum_Project"
                    href="#"
                  >
                    Profile
                  </Breadcrumb.Item>
                </Breadcrumb>
              </div>
            )}

            {location.pathname === PATH.GENERATE_BLOG &&
              parseInt(user?.RoleId) === 1 && (
                <div className="col-12">
                  <Breadcrumb className="header-breadcrumb">
                    <Breadcrumb.Item
                      className="breadcrum_Project"
                      href="#"
                      onClick={() => navigate("/user")}
                    >
                      User &gt;
                    </Breadcrumb.Item>
                    <Breadcrumb.Item
                      className="breadcrum_Project"
                      href="#"
                      onClick={() => navigate("/user-profile")}
                    >
                      Blog &gt;
                    </Breadcrumb.Item>
                    <Breadcrumb.Item
                      active
                      className="breadcrum_Project"
                      href="#"
                    >
                      Blog Generator
                    </Breadcrumb.Item>
                  </Breadcrumb>
                </div>
              )}
            {location.pathname === PATH.GENERATE_BLOG &&
              parseInt(user?.RoleId) === 2 && (
                <div className="col-12">
                  <Breadcrumb className="header-breadcrumb">
                    <Breadcrumb.Item
                      active
                      className="breadcrum_Project"
                      href="#"
                    >
                      View Blog
                    </Breadcrumb.Item>
                  </Breadcrumb>
                </div>
              )}
            {location.pathname === PATH.BLOG_REQUEST && (
              <div className="col-12">
                <Breadcrumb className="header-breadcrumb">
                  <Breadcrumb.Item
                    className="breadcrum_Project"
                    href="#"
                    active
                  >
                    Blog Request
                  </Breadcrumb.Item>
                </Breadcrumb>
              </div>
            )}
            {location.pathname === PATH.MY_BLOG && (
              <div className="col-12 head-breadcrumb">
                <Breadcrumb className="header-breadcrumb">
                  <Breadcrumb.Item
                    active
                    className="breadcrum_Project"
                    href="#"
                  >
                    My Blogs
                  </Breadcrumb.Item>
                </Breadcrumb>
              </div>
            )}
            {location.pathname === PATH.USER_BLOG_REQUEST && (
              <div className="col-12 head-breadcrumb">
                <Breadcrumb className="header-breadcrumb">
                  <Breadcrumb.Item
                    active
                    className="breadcrum_Project"
                    href="#"
                  >
                    Blog Requested
                  </Breadcrumb.Item>
                </Breadcrumb>
              </div>
            )}
          </>
        </div>
        {/* /Logo */}

        {/* /Mobile Menu Toggle */}
        <ul className="nav user-menu ">
          <li className="nav-item dropdown noti-dropdown pr-0 notification_dropdown">
            {/* <Notification /> */}
          </li>
          {(location.pathname.includes("/user-profile") ||
            // location.pathname.includes("/generate-blog") ||
            location.pathname.includes("/blog-request")) && (
            <li className="d-flex align-self-center me-4">
              {/* <button className="btn-header" onClick={() => onGenerateBlog()}>
                Generate Blog
              </button> */}
            </li>
          )}
          {location.pathname.includes("/user-dashboard") && (
            <li className="d-flex align-self-center me-4">
              <button
                className="btn-header"
                // onClick={() => setRequestBlogModal(true)}
                onClick={() => setGenerateRequestBlogModal(true)}
                data-tut="reactour__genrateBlogRequests"
              >
                Request Blog
              </button>
            </li>
          )}
          {location.pathname.includes("/my-blog") && (
            <li className="d-flex align-self-center me-4">
              <button
                className="btn-header"
                // onClick={() => setRequestBlogModal(true)}
                onClick={() => setGenerateRequestBlogModal(true)}
                data-tut="reactour__genrateBlogRequests"
              >
                Request Blog
              </button>
            </li>
          )}
          {location.pathname.includes("/user-blog-request") && (
            <li className="d-flex align-self-center me-4">
              <button
                className="btn-header"
                // onClick={() => setRequestBlogModal(true)}
                onClick={() => setGenerateRequestBlogModal(true)}
                data-tut="reactour__genrateBlogRequests"
              >
                Request Blog
              </button>
            </li>
          )}
          {/* {parseInt(user?.RoleId) === 1 && (
            <li className="d-flex align-self-center  ">
              <div className="d-flex justify-content-end m-1 user-notify">
                <Dropdown className="position-relative">
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    <img
                      src={IMAGES.BELL_ICON}
                      className="header-icon"
                      alt="bell"
                    />
                  </Dropdown.Toggle>
                  <Badge className="notification-icon">9</Badge>
                  <Dropdown.Menu className="border-0">
                    <Dropdown.Item>
                      <div className="d-flex justify-content-between mb-2">
                        <span className="font-weight-600 align-items-center fs-6">
                          Notifications
                        </span>
                        <button className="view-btn ms-4">View All</button>
                      </div>
                      <div>
                        <div className="d-flex justify-content-end">
                          <span>03/Sep/2023</span>
                        </div>
                        <div className="d-flex justify-content-between ">
                          <div className="notification-img">
                            <span className="notify-name">XS</span>
                          </div>
                          <span className="align-self-center">
                            <strong>Xeven Solutions Pvt Ltd</strong> sent you a
                            blog request
                          </span>
                        </div>
                        <div style={{ marginLeft: "42px" }}>
                          <button className="accept-btn me-2">Accept</button>
                          <button
                            className="reject-btn"
                            onClick={() => setRejectionBlogModal(true)}
                          >
                            Reject
                          </button>
                        </div>
                      </div>
                      <hr></hr>
                      <div>
                        <div className="d-flex justify-content-end">
                          <span>03/Sep/2023</span>
                        </div>
                        <div className="d-flex justify-content-between ">
                          <div className="notification-img">
                            <span className="notify-name">XS</span>
                          </div>
                          <span className="align-self-center">
                            <strong>Xeven Solutions Pvt Ltd</strong> sent you a
                            blog request
                          </span>
                        </div>
                        <div style={{ marginLeft: "42px" }}>
                          <button className="accept-btn me-2">Accept</button>
                          <button
                            className="reject-btn"
                            onClick={() => setRejectionBlogModal(true)}
                          >
                            Reject
                          </button>
                        </div>
                      </div>
                      <hr></hr>
                      <div>
                        <div className="d-flex justify-content-end">
                          <span>03/Sep/2023</span>
                        </div>
                        <div className="d-flex justify-content-between ">
                          <div className="notification-img">
                            <span className="notify-name">XS</span>
                          </div>
                          <span className="align-self-center">
                            <strong>Xeven Solutions Pvt Ltd</strong> sent you a
                            blog request
                          </span>
                        </div>
                        <div style={{ marginLeft: "42px" }}>
                          <button className="accept-btn me-2">Accept</button>
                          <button
                            className="reject-btn"
                            onClick={() => setRejectionBlogModal(true)}
                          >
                            Reject
                          </button>
                        </div>
                      </div>
                      <hr></hr>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </li>
          )} */}
          <li className="d-flex align-self-center  ">
            <div className="d-flex justify-content-end m-1 user-notify">
              <Dropdown className="position-relative">
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  <img
                    src={IMAGES.BELL_ICON}
                    className="header-icon"
                    alt="bell"
                    onClick={() => onHandleNotification()}
                  />
                  {countState > 0 ? (
                    <Badge
                      className="notification-icon"
                      onClick={() => onHandleNotification()}
                    >
                      {countState}
                    </Badge>
                  ) : null}
                </Dropdown.Toggle>

                <Dropdown.Menu className="border-0">
                  <Dropdown.Item>
                    <div className="d-flex justify-content-between mb-2">
                      <span className="font-weight-600 align-items-center fs-6">
                        Notifications
                      </span>
                      {/* <button className="view-btn ms-4">View All</button> */}
                      <button
                        className="view-btn ms-4"
                        onClick={() => postData()}
                      >
                        Clear All
                      </button>
                    </div>
                    {/* {console.log("getNotificationData getAllNotificationSuccess ",getNotificationData?.getAllNotificationSuccess)}
                    {console.log("getNotificationData getAllNotificationLoading",getNotificationData?.getAllNotificationLoading)}
                    {console.log("getNotificationData getAllNotificationFailure",getNotificationData?.getAllNotificationFailure)} */}

                    {getNotificationData?.getAllNotificationData?.length > 0
                      ? getNotificationData?.getAllNotificationData?.map(
                          (item, index) => {
                            return (
                              <>
                                <div>
                                  <div className="d-flex justify-content-end">
                                    <span>
                                      {item?.notification_date
                                        ? item?.notification_date
                                        : "03/Sep/2023"}
                                    </span>
                                  </div>
                                  <div className="d-flex justify-content-between ">
                                    <div className="notification-img">
                                      <span className="notify-name">
                                        {item?.notification?.slice(0, 2)}
                                      </span>
                                    </div>
                                    <span className="align-self-center">
                                      {parseInt(user?.RoleId) === 2 ? (
                                        item.isRead === false ? (
                                          <strong className="me-2 notification-unread">
                                            Your blog request is{" "}
                                            {item?.notification} by admin
                                          </strong>
                                        ) : (
                                          <strong className="me-2">
                                            Your blog request is{" "}
                                            {item?.notification} by admin
                                          </strong>
                                        )
                                      ) : item.isRead === false ? (
                                        <strong className="me-2 notification-unread">
                                          {item?.notification}
                                        </strong>
                                      ) : (
                                        <strong className="me-2">
                                          {item?.notification}
                                        </strong>
                                      )}
                                    </span>
                                  </div>
                                </div>
                              </>
                            );
                          }
                        )
                      : "Notification Not Available"}
                    <hr></hr>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </li>

          <li className="nav-item dropdown has-arrow ">
            <Dropdown className="user-dropdown  h-100">
              <Dropdown.Toggle
                variant="success"
                id="dropdown-basic"
                className="user_dropdown h-100 d-flex align-items-center"
              >
                <span className="user-img mt-0">
                  {/* <img
                    className="rounded-circle"
                    src={IMAGES.USER_HEADER_IMG}
                    width="31"
                    alt="Ryan Taylor"
                  /> */}
                  <div className="username-heading">{UserNameTwoCharacter}</div>
                  <span className="text-black text-black-role ms-2">
                    {user?.UserName}{" "}
                    <span className="role-setting">
                      {parseInt(user?.RoleId) === 1 ? "Admin" : "User"}
                    </span>
                  </span>
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="profile-dropmenu">
                <Dropdown.Item
                  className="d-block px-0"
                  onClick={() => setProfileModal(true)}
                >
                  <AiFillSetting fill="#0971ad" className="me-2" />
                  Settings
                </Dropdown.Item>
                <Dropdown.Item
                  className="d-block px-0"
                  onClick={() => Logout()}
                >
                  <FaSignOutAlt fill="#0971ad" className="me-2" />
                  Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </li>
        </ul>
        {/* <ReactTooltip /> */}
      </div>
      <SessionExpireModal />
      <GenerateBlogModal
        show={generateBlogModal}
        handleClose={() => setGenerateBlogModal(false)}
      />
      <BlogRequestModal
        show={requestBlogModal}
        handleClose={() => setRequestBlogModal(false)}
        title="Request a blog"
        requestBlog={requestBlog}
      />
      <GenerateBlogRequestModal
        show={GenerateRequestBlogModal}
        handleClose={() => setGenerateRequestBlogModal(false)}
        generateRequestBlog={GenerateRequestBlog}
      />
      <RequestSentModal
        handleClose={() => setRequestSentModal(false)}
        show={requestSentModal}
      />
      <RejectionBlogModal
        handleClose={() => setRejectionBlogModal(false)}
        show={rejectionBlogModal}
      />
      <ProfileUpdateModal
        handleClose={() => setProfileModal(false)}
        show={profileModal}
      />
    </>
  );
}
function SessionExpireModal() {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const { sessionExpire } = useSelector((state) => state.user);
  // if(sessionExpire===true){
  //   localStorage?.removeItem("hea_user", { path: "/" });
  //   localStorage?.removeItem("hea_user_token");
  //   dispatch(success(USER.SET_SESSION_EXPIRE_STATE, false));
  //   window.localStorage.removeItem("pmsTokenExpiry");
  //   navigate(PATH.LOGIN);
  // }
  const reLogin = () => {
    localStorage?.removeItem("hea_user", { path: "/" });
    localStorage?.removeItem("hea_user_token");
    dispatch(success(USER.SET_SESSION_EXPIRE_STATE, false));
    window.localStorage.removeItem("heaTokenExpiry");
    navigate(PATH.LOGIN);
  };

  return (
    <Modal
      size="sm"
      className="session-out-modal"
      centered
      show={sessionExpire}
    >
      <Modal.Body closeButton className="text-center mt-3">
        <h4>
          Session Expired
          <br />
          <br />
          <span className="mt-2">
            Your current session has been expired, please login again to
            conitnue
          </span>
        </h4>
        <Button
          size="sm"
          className="mt-3"
          variant="primary"
          onClick={() => reLogin()}
        >
          Re-Login
        </Button>
      </Modal.Body>
    </Modal>
  );
}
