import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { PATH } from "../utils/path";
import FullscreenLoader from "./FullScreenLoader.jsx";

function PublicRoute({ element }) {
  const navigate = useNavigate();
  const userToken = localStorage?.getItem("hea_user_token");
  const user = JSON?.parse(localStorage?.getItem("hea_user"));
  useEffect(() => {
    if (userToken) {
      {
        if (parseInt(user?.RoleId) === 1) {
          navigate(PATH.DASHBOARD);
        } else if (parseInt(user?.RoleId) === 2) {
          navigate(PATH.USER_DASHBOARD);
        }
      }
    }
  }, [userToken]);
  if (userToken === undefined) return <FullscreenLoader />;

  return element;
}

PublicRoute.propTypes = {
  element: PropTypes.node.isRequired,
};

export default PublicRoute;
