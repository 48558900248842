import React, { useState } from "react";
import { useEffect } from "react";
import { Button, Card, Col, Form, Modal, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { AiFillEyeInvisible } from "react-icons/ai";
import { CgEyeAlt } from "react-icons/cg";
import { useDispatch, useSelector } from "react-redux";
import {
  addUserAction,
  editUserAction,
  getAllUserAction,
  getAllUserStats,
  getUserInfoAction,
} from "../../redux/actions/userAction";

import { FieldError } from "../errorMessage/ErrorMessage";
import { Notification } from "../genericActions";

export default function ProfileUpdateModal(props) {
  const { show, handleClose } = props;
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);
  const { userInfoList } = useSelector((state) => state.user);
  const user = JSON.parse(localStorage.getItem("hea_user"));
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    dispatch(getUserInfoAction(user?.Id));
  }, [user?.Id]);
  const onSuccess = () => {
    handleClose();
    reset();

    let finalData = {
      typeId: 1,
      pageNo: 1,
      pageSize: 10,
      searchKey: "",
    };
    dispatch(getAllUserAction(finalData));
    dispatch(getAllUserStats());
  };
  function onSubmit(data) {
    let finalData = {
      UserName: user?.UserName,
      first_name: data.firstName,
      last_name: data.lastName,
      Email: user?.Email,
      Phone: data.phone,
      Company: data.company,
      TotalBlogs: parseInt(data.totalBlogs),
      RemainingBlogs: parseInt(data.totalBlogs),
      Password: data.password,
      ConfirmPassword: data.confirmPassword,
      ActiveStatus: user?.ActiveStatus,
      Description: data.description,
      userId: user?.Id,
    };
    if (finalData.Password === finalData.ConfirmPassword) {
      dispatch(editUserAction(finalData, onSuccess, Notification));
    } else if (finalData.Password !== finalData.ConfirmPassword) {
      Notification("Password and Confirm Password must be same", "error");
    }
  }
  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="md"
        className="user-modal"
      >
        <Modal.Header closeButton className="border-0 justify-between">
          <Modal.Title>
            {parseInt(user?.RoleId) === 1 ? "Profile Detail" : "Update Profile"}
          </Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            <Card.Body>
              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label className="label-primary">
                      First Name
                    </Form.Label>
                    <Form.Control
                      type="text"
                      size="sm"
                      disabled={parseInt(user?.RoleId) === 1 ? true : false}
                      placeholder="Enter Name"
                      defaultValue={userInfoList?.FirstName}
                      {...register("firstName", {
                        required: true,
                        pattern: /\S+/,
                      })}
                    />
                    {errors.firstName && (
                      <FieldError message={"This Field is Required"} />
                    )}
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label className="label-primary">Last Name</Form.Label>
                    <Form.Control
                      type="text"
                      size="sm"
                      disabled={parseInt(user?.RoleId) === 1 ? true : false}
                      placeholder="Enter Name"
                      defaultValue={userInfoList?.LastName}
                      {...register("lastName", {
                        required: true,
                        pattern: /\S+/,
                      })}
                    />
                    {errors.lastName && (
                      <FieldError message={"This Field is Required"} />
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label className="label-primary">Email</Form.Label>
                    <Form.Control
                      type="email"
                      size="sm"
                      disabled={parseInt(user?.RoleId) === 1 ? true : false}
                      defaultValue={userInfoList?.Email}
                      placeholder="Enter Email"
                      {...register("Email")}
                    />
                    {errors.Email && (
                      <FieldError message={"This Field is Required"} />
                    )}
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label className="label-primary">Status</Form.Label>
                    <Form.Select
                      {...register("ActiveStatus")}
                      aria-label="Default select example"
                      size="sm"
                      disabled
                      defaultValue={
                        userInfoList?.ActiveStatus === true
                          ? "active"
                          : "inactive"
                      }
                    >
                      <option value="active">Active</option>
                      <option value="inactive">Inactive</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label className="label-primary">Phone No</Form.Label>
                    <Form.Control
                      type="text"
                      disabled={parseInt(user?.RoleId) === 1 ? true : false}
                      size="sm"
                      placeholder="Enter Phone No"
                      defaultValue={userInfoList?.Phone}
                      {...register("phone", {
                        required: true,
                        // pattern: /^[0-9]{11,}$/,
                      })}
                    />
                    {errors.phone && (
                      <FieldError message={"This Field is Required"} />
                    )}
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label className="label-primary">Company</Form.Label>
                    <Form.Control
                      type="text"
                      disabled={parseInt(user?.RoleId) === 1 ? true : false}
                      defaultValue={userInfoList?.Company}
                      size="sm"
                      placeholder="Enter Company Name"
                      {...register("company", {
                        required: true,
                        pattern: /\S+/,
                      })}
                    />
                    {errors.company && (
                      <FieldError message={"This Field is Required"} />
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label className="label-primary">
                      Total # of Blogs/Month
                    </Form.Label>
                    <Form.Control
                      size="sm"
                      type="text"
                      disabled={parseInt(user?.RoleId) === 1 ? true : false}
                      defaultValue={userInfoList?.TotalBlogs}
                      placeholder="Enter Total # of Blogs"
                      {...register("totalBlogs", {
                        required: true,
                        pattern: /\S+/,
                      })}
                    />
                    {errors.totalBlogs && (
                      <FieldError message={"This Field is Required"} />
                    )}
                  </Form.Group>
                </Col>
                {/* <Col>
                  <Form.Group className="mb-3">
                    <Form.Label className="label-primary">
                      Remaining Blogs/Month
                    </Form.Label>
                    <Form.Control
                      size="sm"
                      type="text"
                      disabled={parseInt(user?.RoleId) === 1 ? true : false}
                      defaultValue={userInfoList?.RemainingBlogs}
                      placeholder="Enter Remaining Blogs/Month"
                      {...register("remainingBlogs", {
                        required: true,
                        pattern: /\S+/,
                      })}
                    />
                    {errors.remainingBlogs && (
                      <FieldError message={"This Field is Required"} />
                    )}
                  </Form.Group>
                </Col> */}
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3 position-relative">
                    <Form.Label className="label-primary">
                      Change Password
                    </Form.Label>
                    <Form.Control
                      type={showPassword ? "text" : "password"}
                      size="sm"
                      disabled={parseInt(user?.RoleId) === 1 ? true : false}
                      placeholder="Enter Password"
                      defaultValue={userInfoList?.Password}
                      {...register("password", {
                        required: true,
                        pattern: /\S+/,
                      })}
                    />
                    <div onClick={handleClickShowPassword} className="eye-icon">
                      {showPassword ? <CgEyeAlt /> : <AiFillEyeInvisible />}
                    </div>
                    {errors.password && (
                      <FieldError message={"This Field is Required"} />
                    )}
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3 position-relative">
                    <Form.Label className="label-primary">
                      Confirm Password
                    </Form.Label>
                    <Form.Control
                      type={showConfirmPassword ? "text" : "password"}
                      size="sm"
                      disabled={parseInt(user?.RoleId) === 1 ? true : false}
                      defaultValue={userInfoList?.Password}
                      placeholder="Enter Confirm Password"
                      {...register("confirmPassword", {
                        required: true,
                        pattern: /\S+/,
                      })}
                    />
                    <div
                      onClick={handleClickShowConfirmPassword}
                      className="eye-icon"
                    >
                      {showConfirmPassword ? (
                        <CgEyeAlt />
                      ) : (
                        <AiFillEyeInvisible />
                      )}
                    </div>
                    {errors.confirmPassword && (
                      <FieldError message={"This Field is Required"} />
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label className="label-primary">Detail</Form.Label>
                    <Form.Control
                      as="textarea"
                      placeholder="Add Description"
                      style={{ height: "62px" }}
                      disabled={parseInt(user?.RoleId) === 1 ? true : false}
                      defaultValue={userInfoList?.Description}
                      {...register("description", {
                        required: true,
                        pattern: /\S+/,
                      })}
                    />
                    {errors.description && (
                      <FieldError message={"This Field is Required"} />
                    )}
                  </Form.Group>
                </Col>
              </Row>
            </Card.Body>
          </Modal.Body>
          <Modal.Footer className="border-0 ">
            <h6 className="me-3 cursor btn-closeAndBack" onClick={handleClose}>
              Cancel
            </h6>
            {parseInt(user?.RoleId) === 2 && (
              <Button className="btn-primary border-0 px-3" type="submit">
                Update
              </Button>
            )}
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
}
