/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
export function Footer() {
  return (
    <>
      <div className="footer">
        <p className="text-center m-0">
          All Rights Reserved @ HealthCare Entrepreneur Academy
        </p>
      </div>
    </>
  );
}
