import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { ParentComponentWithSideBar } from "../components/layout/ParentComponentWithSideBar";
import { useAuth } from "./ProvidedAuth";
import { PATH } from "../utils/path";
;
function ProtectedRoute({ element }) {
  const user = JSON.parse(localStorage.getItem("hea_user"))
  console.log("test",user)
  const navigate = useNavigate();
  useEffect(() => {
    if (!parseInt(user?.RoleId)) {
      navigate(PATH.LOGIN);
    }
  }, [navigate, user]);

  return <ParentComponentWithSideBar>{element}</ParentComponentWithSideBar>;
}

ProtectedRoute.propTypes = {
  element: PropTypes.node.isRequired,
};

export default ProtectedRoute;
