import axios from "axios";
import APP_SETTINGS from "./config";

export const axiosInterceptors = (token) => {
  const resp = axios.interceptors.request.use((config) => {
    if (config?.url !== APP_SETTINGS.API_PATH.userLogin && token) {
      config.headers = {
        ...config.headers,
        authorization: `Bearer ${token}`,
      };
      return config;
    }
    return config;
  });
  return resp;
  
};
