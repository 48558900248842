import React from "react";
const Login = React.lazy(() => import("./login/index.jsx"));
const Dashboard = React.lazy(() => import("./adminSide/dashboard/index.jsx"));
const User = React.lazy(() => import("./adminSide/users/index.jsx"));
const UserProfile = React.lazy(() =>
  import("./adminSide/users/UserProfile.jsx")
);
const GenerateBlog = React.lazy(() =>
  import("./adminSide/generateBlogs/index.jsx")
);
const BlogRequest = React.lazy(() =>
  import("./adminSide/blogRequest/index.jsx")
);
const UserDashboard = React.lazy(() =>
  import("./userSide/userDashboard/index.jsx")
);
const MyBlog = React.lazy(() => import("./userSide/blogs/index.jsx"));
const UserBlogRequest = React.lazy(() =>
  import("./userSide/blogRequest/index.jsx")
);

const WEB_PAGES = {
  LOGIN: Login,
  DASHBOARD: Dashboard,
  USER: User,
  USER_PROFILE: UserProfile,
  GENERATE_BLOG: GenerateBlog,
  BLOG_REQUEST: BlogRequest,
  USER_DASHBOARD: UserDashboard,
  MY_BLOG: MyBlog,
  USER_BLOG_REQUEST: UserBlogRequest,
};
export default WEB_PAGES;
