import {
  getAllBlogsApi,
  getRequestedBlogsByUserApi,
  getRequestedBlogApi,
  requestBlogApi,
  blogChangestatusApi,
} from "../api/blogsApi";
import { BLOGS, failure, request, success, USER } from "./utilities";
export const getRequestedBlogsByUsersAction = (data) => {
  return (dispatch) => {
    dispatch(request(BLOGS.GET_REQUESTED_BLOG_USER_DATA_LOADING));
    getRequestedBlogsByUserApi(data).then(
      (response) => {
        if (response.status === 200) {
          dispatch(
            success(BLOGS.GET_REQUESTED_BLOG_USER_DATA_SUCCESS, response.data)
          );
        } else {
          dispatch(
            failure(
              BLOGS.GET_REQUESTED_BLOG_USER_DATA_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            BLOGS.GET_REQUESTED_BLOG_USER_DATA_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
};
export const getAllBlogsAction = (data) => {
  return (dispatch) => {
    dispatch(request(BLOGS.GET_ALL_BLOG_LOADING));
    getAllBlogsApi(data).then(
      (response) => {
        if (response.status === 200) {
          dispatch(success(BLOGS.GET_ALL_BLOG_SUCCESS, response.data));
        } else {
          dispatch(failure(BLOGS.GET_ALL_BLOG_FAILURE, response.data.message));
        }
      },
      (error) => {
        dispatch(
          failure(
            BLOGS.GET_ALL_BLOG_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
};

export const getRequestedBlogAction = (data) => {
  return (dispatch) => {
    dispatch(request(BLOGS.GET_REQUESTED_BLOG_LOADING));
    getRequestedBlogApi(data).then(
      (response) => {
        if (response.status === 200) {
          dispatch(success(BLOGS.GET_REQUESTED_BLOG_SUCCESS, response.data));
        } else {
          dispatch(
            failure(BLOGS.GET_REQUESTED_BLOG_FAILURE, response.data.message)
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            BLOGS.GET_REQUESTED_BLOG_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
};

export const BlogChangestatusAction = (data, Notification, onSuccess) => {
  return (dispatch) => {
    dispatch(request(BLOGS.CHANGE_REQUESTED_BLOG_STATUS_LOADING));
    blogChangestatusApi(data).then(
      (response) => {
        if (response.status === 200) {
          dispatch(
            success(BLOGS.CHANGE_REQUESTED_BLOG_STATUS_SUCCESS, response.data)
          );
          if (Notification) {
            Notification("Status Change Successfully", "success");
          }
          if (onSuccess) {
            onSuccess();
          }
        } else {
          dispatch(
            failure(
              BLOGS.CHANGE_REQUESTED_BLOG_STATUS_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            BLOGS.CHANGE_REQUESTED_BLOG_STATUS_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
};
export const requestBlogAction = (data, Notification, onSuccess) => {
  return (dispatch) => {
    dispatch(request(BLOGS.REQUEST_BLOG_LOADING));
    requestBlogApi(data).then(
      (response) => {
        if (response.data.httpStatusCode === 200) {
          dispatch(success(BLOGS.REQUEST_BLOG_SUCCESS, response.data));
          if (Notification) {
            Notification("Request Generated Successfully", "success");
          }
          if (onSuccess) {
            onSuccess();
          }
        } else {
          dispatch(failure(BLOGS.REQUEST_BLOG_FAILURE, response.data.message));
          if (Notification) {
            Notification(
              "Remaining blogs limit completed, can't sent request ",
              "error"
            );
          }
          if (onSuccess) {
            onSuccess();
          }
        }
      },
      (error) => {
        dispatch(
          failure(
            BLOGS.REQUEST_BLOG_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? Notification(error.response.data.message, "error")
              : Notification(error.message, "error")
          )
        );
      }
    );
  };
};
