import axios from "axios";
import APP_SETTINGS from "../../config";

export function getAllBlogsApi(data) {
  return axios.post(APP_SETTINGS.API_PATH.generateBlog, data);
}
export const getRequestedBlogsByUserApi = (data) => {
  return axios.get(
    `${APP_SETTINGS.API_PATH.getallRequestedBlogsdata}?type=${data?.type}&pageNo=${data?.pageNo}&pageSize=${data?.pageSize}&searchKey=${data.searchKey}`
  );
};
export function requestBlogApi(data) {
  return axios.post(
    `${APP_SETTINGS.API_PATH.requestBlog}?requestQuantity=${data?.requestQuantity}&userID=${data?.userID}`
  );
}

export function getRequestedBlogApi(data) {
  return axios.post(APP_SETTINGS.API_PATH.generateBlog, data);
}
export function blogChangestatusApi(data) {
  return axios.post(APP_SETTINGS.API_PATH.blogChangestatus, data);
}
