/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link, useLocation } from "react-router-dom";
import "../../assets/js/jquerry.slimscroll";
import { MdDashboardCustomize, MdOutlineSpaceDashboard } from "react-icons/md";
import { HiUsers } from "react-icons/hi";
import IMAGES from "../../assets/images";
import { PATH } from "../../utils/path.jsx";
import { FaBars, FaBloggerB } from "react-icons/fa";
import { useAuth } from "../../authorization/ProvidedAuth";
import TotalUsers from "../../pages/adminSide/dashboard/TotalUsers";
import Highcharts from "highcharts/highcharts";
import HighchartsReact from "highcharts-react-official";
import {
  monthlyBlogsAction,
  monthlyUsersAction,
} from "../../redux/actions/userAction";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

export default function Sidebar() {
  const auth = useAuth();
  const user = JSON.parse(localStorage.getItem("hea_user"));
  let location = useLocation();
  let dispatch = useDispatch();
  useEffect(() => {
    let finalData = {
      year: 2023,
    };
    dispatch(monthlyBlogsAction(finalData));
    dispatch(monthlyUsersAction(finalData));
  }, []);
  let dashbboardData = useSelector((state) => state?.user);
  const userData = {
    chart: {
      height: (9 / 16) * 100 + "%",
    },
    title: {
      text: "",
    },
    credits: {
      enabled: false,
    },
    subtitle: {
      text: "",
    },
    xAxis: {
      categories: [
        "0",
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
    yAxis: {
      title: {
        text: null,
      },
    },
    exporting: { enabled: false },
    series: [
      {
        type: "column",
        name: "Users",
        colorByPoint: true,
        data: dashbboardData?.getMonthlyUsers
          ? dashbboardData?.getMonthlyUsers
          : {},
        showInLegend: false,
      },
    ],
  };
  const blogsData = {
    chart: {
      height: (9 / 16) * 100 + "%",
    },
    title: {
      text: "",
    },
    credits: {
      enabled: false,
    },
    subtitle: {
      text: "",
    },
    xAxis: {
      categories: [
        "0",
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
    yAxis: {
      title: {
        text: null,
      },
    },
    exporting: { enabled: false },
    series: [
      {
        type: "column",
        name: "Blogs",
        colorByPoint: true,
        data: dashbboardData?.getMonthlyBlogs
          ? dashbboardData?.getMonthlyBlogs
          : {},
        showInLegend: false,
      },
    ],
  };
  return (
    <div className="sidebar" id="sidebar">
      <div className="sidebar-inner slimscroll">
        <div id="sidebar-menu" className="sidebar-menu">
          <div className="header-left mb-2 d-flex pageName">
            <Link
              to=""
              className="logo mr-0 header-logo-image text-decoration-none"
            >
              <img alt="ajhgs" src={IMAGES.LOGO} className="sidebar-log-img" />{" "}
            </Link>
            <a href="javascript:void(0)" id="toggle_btn">
              <FaBars className="toggleset" />
            </a>
          </div>

          <ul>
            <>
              {parseInt(user?.RoleId) === 1 && (
                <>
                  <li
                    className={
                      location.pathname === PATH.DASHBOARD ? "active" : ""
                    }
                  >
                    <Link
                      to={{
                        pathname: PATH.DASHBOARD,
                      }}
                      className=""
                      data-tut="reactour__iso"
                    >
                      {" "}
                      <MdDashboardCustomize className="" />
                      <span>Dashboard</span>
                    </Link>
                    <div className="tooltip-box2">Dashboard</div>
                  </li>
                </>
              )}
              {parseInt(user?.RoleId) === 1 && (
                <li
                  className={
                    location.pathname === PATH.USER ||
                    location.pathname.includes("/user-profile")
                      ? "active"
                      : ""
                  }
                >
                  <Link
                    to={{
                      pathname: PATH.USER,
                    }}
                    className=""
                    data-tut="reactour__user_page"
                  >
                    {" "}
                    <HiUsers className="" />
                    <span>Users</span>
                  </Link>
                  <div className="tooltip-box2">Users</div>
                </li>
              )}
              {parseInt(user?.RoleId) === 1 && (
                <li
                  className={
                    location.pathname === PATH.BLOG_REQUEST ||
                    location.pathname === PATH.GENERATE_BLOG
                      ? "active"
                      : ""
                  }
                >
                  <Link
                    to={{
                      pathname: PATH.BLOG_REQUEST,
                    }}
                    className=""
                    data-tut="reactour__blog_request"
                  >
                    {" "}
                    <FaBloggerB className="" />
                    <span>Blog Request</span>
                  </Link>
                  <div className="tooltip-box2">Blog Request</div>
                </li>
              )}
              {parseInt(user?.RoleId) === 2 && (
                <li
                  className={
                    location.pathname === PATH.USER_DASHBOARD ? "active" : ""
                  }
                  
                >
                  <Link
                    to={{
                      pathname: PATH.USER_DASHBOARD,
                    }}

                    className=""
                    data-tut="reactour__dashboardUser"
                  >
                    {" "}
                    <MdDashboardCustomize className="" />
                    <span>Dashboard</span>
                  </Link>
                  <div className="tooltip-box2">Dashboard</div>
                </li>
              )}
              {parseInt(user?.RoleId) === 2 && (
                <li
                  className={location.pathname === PATH.MY_BLOG||location.pathname === PATH.GENERATE_BLOG ? "active" : ""}
                >
                  <Link
                    to={{
                      pathname: PATH.MY_BLOG,
                    }}
                    className=""
                    data-tut="reactour__myBlogs"
                  >
                    {" "}
                    <MdOutlineSpaceDashboard className="" />
                    <span>My Blogs</span>
                  </Link>
                  <div className="tooltip-box2">My Blogs</div>
                </li>
              )}
              {parseInt(user?.RoleId) === 2 && (
                <li
                  className={
                    location.pathname === PATH.USER_BLOG_REQUEST ? "active" : ""
                  }
                >
                  <Link
                    to={{
                      pathname: PATH.USER_BLOG_REQUEST,
                    }}
                    className=""
                    data-tut="reactour__blogRequests"
                  >
                    {" "}
                    <FaBloggerB className="" />
                    <span>Blog Requested</span>
                  </Link>
                  <div className="tooltip-box2">Blog Requested</div>
                </li>
              )}
            </>
          </ul>
          {parseInt(user?.RoleId) === 1 && (
            <>
              <div className="sidebar-graph text-white mt-4 pt-3">
                <p className="side-bar-graphs-title">Total Users</p>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={userData ? userData : " "}
                  isPureConfig={true}
                />
              </div>
              <div className="sidebar-graph text-white mt-4 pt-3">
                <p className="side-bar-graphs-title">Total Blogs</p>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={blogsData ? blogsData : " "}
                  isPureConfig={true}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
