const baseUrl = "https://heafastapi.xeventechnologies.com";
// const baseUrlFastApi = "http://20.85.227.97:8000/";
const baseUrlFastApi = "https://healthcare-383sq.ondigitalocean.app/";
// const baseUrlFastApi = "https://searchfuelapp.healthcareboss.org/";

const APP_SETTINGS = {
  API_PATH: {
    userLogin: baseUrlFastApi + "login",
    getAllUsers: baseUrlFastApi + "users",
    getUserStats: baseUrlFastApi + "users/stats",
    getStats: baseUrlFastApi + "blog",
    addUser: baseUrlFastApi + "users",
    archiveUser: baseUrlFastApi + "users/archive",
    archiveBlog: baseUrlFastApi + "archiverequest",
    unArchiveBlog: baseUrlFastApi + "unarchiverequest",
    editUser: baseUrlFastApi + "users/",
    deleteUser: baseUrlFastApi + "users",
    getUserDetails: baseUrlFastApi + "userblogrequestdetail",
    user: baseUrl + "user",
    user_blog: baseUrlFastApi + "getblog",
    userInfo: baseUrlFastApi + "userinfo",
    getallRequestedBlogsdata: baseUrlFastApi + "requestblog",
    blogChangestatus: baseUrlFastApi + "changestatus",
    requestBlog: baseUrlFastApi + "requestblog",
    deleteBlog: baseUrlFastApi + "deleteblog",
    monthlyBlogs: baseUrlFastApi + "monthly-blogs",
    monthlyUsers: baseUrlFastApi + "monthly-users",
    TotalRecords: baseUrlFastApi + "TotalRecords",
    latestBlogs: baseUrlFastApi + "getallblogs",
    withoutfilter: baseUrlFastApi + "blogs/getallblogs/withoutfilter",
    blogPost: baseUrlFastApi + "blogpost",
    undoBlog: baseUrlFastApi + "undo",
    pushToClient: baseUrlFastApi + "pushtoclient",
    blogtodraft: baseUrlFastApi + "blogtodraft",
    getblogtitle: baseUrlFastApi + "getblogtitle",
    regeneratetitle: baseUrlFastApi + "regeneratetitle",
    getDraftBlog: baseUrlFastApi + "getdraftblog",
    getBlogHistory: baseUrlFastApi + "getbloghistory",
    getUserFilter: baseUrlFastApi + "user/filter",
    getBlogDataFilter: baseUrlFastApi + "blogdata/filter",
    updateBlog: baseUrlFastApi + "updatedescriotion",
    updateTitle: baseUrlFastApi + "updatetitle",
    deleterequest: baseUrlFastApi + "deleterequest",
    getallnotifications: baseUrlFastApi + "getallnotifications",
    userUnarchive: baseUrlFastApi + "users/unarchive",
    requestUnarchive: baseUrlFastApi + "unarchiverequest",
    changeFirstTimeVisit: baseUrlFastApi + "changeFirstTimeVisit",
    BLOGS_APIS: {
      generateBlog: baseUrl + "/generate_blog_from_prompt",
    },
  },
};
export default APP_SETTINGS;
