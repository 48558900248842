import React, { useState } from "react";
import { Button, Card, Col, Dropdown, Form, Row } from "react-bootstrap";
import Highcharts from "highcharts/highcharts";
import HighchartsReact from "highcharts-react-official";
import {
  MdFilterAlt,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from "react-icons/md";
// import IMAGES from "../../../assets/images";
// import { FaSignOutAlt } from "react-icons/fa";
import { FieldError } from "../../../components/errorMessage/ErrorMessage";
import { useForm } from "react-hook-form";
import {
  getUserFilter,
  monthlyUsersAction,
} from "../../../redux/actions/userAction";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Notification } from "../../../components/genericActions";

export default function TotalUsers(props) {
  const currentYear = new Date().getFullYear();

  const [countYear, setcountYear] = useState(currentYear);
  const [startDate, setstartDate] = useState({
    isSelected: false,
    startDay: "",
  });
  const [showDropdown, setShowDropdown] = useState(false);

  let dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  let dashbboardData = useSelector((state) => state?.user);
  useEffect(() => {
    let finalData = {
      year: countYear,
    };

    dispatch(monthlyUsersAction(finalData));
  }, [countYear]);

  const onSuccess = () => {
    reset();
    setShowDropdown(false);
    setstartDate({
      isSelected: false
    });

  };

  function onSubmit(data) {
    let finalData = {
      prev: startDate.startDay,
      curr: data?.endDate,
    };

    console.group("finalData", finalData);
    dispatch(getUserFilter(finalData, onSuccess, Notification));
  }

  const leadsPerMonth = {
    chart: {
      height: (9 / 12) * 52 + "%",
    },
    title: {
      text: "",
    },
    credits: {
      enabled: false,
    },
    subtitle: {
      text: "",
    },
    xAxis: {
      categories: [
        "0",
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
    yAxis: {
      title: {
        text: null,
      },
    },
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 200,
          },
          chartOptions: {
            legend: {
              enabled: false,
            },
          },
        },
      ],
    },
    exporting: { enabled: false },
    series: [
      {
        type: "column",
        name: "Users",
        colorByPoint: true,
        data: dashbboardData?.getMonthlyUsers
          ? dashbboardData?.getMonthlyUsers
          : {},
        showInLegend: false,
      },
    ],
  };

  const handleShow = () => {
    setShowDropdown(!showDropdown);
  };

  return (
    <div>
      <Card className="border-0 h-100 mb-4">
        <Card.Body>
          <Row className="mb-3">
            <Col lg={12} className="total-user-component">
              <div className="d-flex flex-wrap justify-content-between align-items-center mb-2">
                <h5
                  className="generic-heading-level-2 generic-theme-color "
                  data-tut="reactour__user_graph"
                >
                  Total Users
                </h5>
                <p className="mb-0">
                  {" "}
                  <MdKeyboardArrowLeft
                    className="me-3 cursor mb5px"
                    onClick={() => {
                      setcountYear(countYear - 1);
                    }}
                  />
                  {countYear}{" "}
                  <MdKeyboardArrowRight
                    className="ms-3 cursor mb5px"
                    onClick={() => {
                      setcountYear(countYear + 1);
                    }}
                  />
                </p>
                {/* <div className="d-flex ">
                  <div className="cursor-pointer text-black">
                    <MdFilterAlt />
                  </div>
                </div> */}
                <div className="  h-100 dashboard-menu">
                  <div
                    variant="success"
                    id="dropdown-basic"
                    className="date-filter-dropdown user_dropdown h-100 d-flex align-items-center"
                  >
                    <div onClick={handleShow} className="d-flex ">
                      <div className="cursor-pointer text-black">
                        <MdFilterAlt />
                      </div>
                    </div>
                  </div>
                  {showDropdown && (
                    <div className="p-2 dashboard-date-menu">
                      <Form onSubmit={handleSubmit(onSubmit)}>
                        <div className="d-block px-0">
                          <Form.Group className="mb-3 mt-2">
                            <Form.Control
                              type="date"
                              size="sm"
                              onChange={(e) => {
                                setstartDate({
                                  isSelected: true,
                                  startDay: e.target.value,
                                });
                              }}
                              placeholder="Start Date"
                              // name="startDate"
                              // defaultValue=""
                              //  {...register("startDate", { required: true })}
                            />
                            {errors.startDate && (
                              <FieldError message={"This Field is Required"} />
                            )}
                          </Form.Group>
                        </div>
                        <div className="d-block px-0">
                          <Form.Group className=" mb-3">
                            {/* <Form.Label className="label-primary">
                        End Date
                      </Form.Label> */}
                            <Form.Control
                              type="date"
                              size="sm"
                              min={startDate.startDay}
                              disabled={!startDate.isSelected}
                              placeholder="End Date"
                              {...register("endDate", { required: true })}
                            />
                            {errors.endDate && (
                              <FieldError message={"This Field is Required"} />
                            )}
                          </Form.Group>
                        </div>
                        <div
                          className="d-block px-0 mt-2"
                          // onClick={() => Logout()}
                        >
                          <Button
                            className="btn btn-primary w-100"
                            type="submit"
                          >
                            Submit
                          </Button>
                        </div>
                      </Form>
                    </div>
                  )}
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="total-users">
              <HighchartsReact
                highcharts={Highcharts}
                options={leadsPerMonth ? leadsPerMonth : "  "}
                isPureConfig={true}
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </div>
  );
}
