import React from "react";
import { useState } from "react";
import { Form, Modal, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { PulseLoader } from "react-spinners";
import { requestBlogAction } from "../../redux/actions/blogsAction";
import { getAllStats, getUserDetailsAction } from "../../redux/actions/userAction";
import { Notification } from "../genericActions";
export default function GenerateBlogRequestModal(props) {
  const [quantity, setQuantity] = useState(null);
  const { show, handleClose, generateRequestBlog } = props;
  const user = JSON.parse(localStorage.getItem("hea_user"));
  let requestBlog = useSelector((state) => state?.blogs);
  let dispatch = useDispatch();
  const onSuccess = () => {
    handleClose();
    let finalData = {
      userId: user?.Id,
      pageNo: 1,
      pageSize: 10,
      typeID: 1,
      searchKey: "",
    };
    dispatch(getAllStats(finalData?.userId));
    dispatch(getUserDetailsAction(finalData));

    setQuantity(null);
  };
  const RequestBlog = () => {
    let finalData = {
      requestQuantity: quantity,
      userID: user?.Id,
    };
    dispatch(requestBlogAction(finalData, Notification, onSuccess));
  };
  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="md"
        // centered
        className="generate-blog-modal"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="align-self-center ">
          <h4 className="blog-modal-header mb-3">Quantity of Blogs</h4>
          <Row>
            <Form.Group className="mb-3">
              <Form.Control
                type="text"
                size="sm"
                onChange={(e) => setQuantity(e.target.value)}
                value={quantity}
                placeholder="Enter Quantity of Blogs"
              />
            </Form.Group>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn-closeAndBack me-3" onClick={handleClose}>
            Cancel
          </button>
          {requestBlog?.requestBlogLoading === true ? (
            <PulseLoader color="#39b6fe" size={5} />
          ) : (
            <button
              className="btn-primary"
              onClick={() => {
                RequestBlog();
              }}
            >
              Submit Request
            </button>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
}
