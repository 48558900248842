import { Routes, Route } from "react-router-dom";
import WEB_PAGES from "./pages";
import { PATH } from "./utils/path";
import PublicRoute from "./authorization/PublicRoute.jsx";
import ProtectedRoute from "./authorization/ProtectedRoute.jsx";
import { axiosInterceptors } from "./AxiosInterceptor";
import { useAuth } from "./authorization/ProvidedAuth";

function RouterConfig() {
  const user = localStorage.getItem("hea_user_token");
  const userRoutes = JSON?.parse(localStorage?.getItem("hea_user"));
  axiosInterceptors(user);
  return (
    <Routes>
      <Route
        path={PATH.LOGIN}
        element={<PublicRoute element={<WEB_PAGES.LOGIN />} />}
      />
      {parseInt(userRoutes?.RoleId) === 1 && (
        <>
          <Route
            path={PATH.DASHBOARD}
            element={<ProtectedRoute element={<WEB_PAGES.DASHBOARD />} />}
          />
          <Route
            path={PATH.USER}
            element={<ProtectedRoute element={<WEB_PAGES.USER />} />}
          />
          <Route
            path={PATH.BLOG_REQUEST}
            element={<ProtectedRoute element={<WEB_PAGES.BLOG_REQUEST />} />}
          />
          <Route
            path={PATH.USER_PROFILE}
            element={<ProtectedRoute element={<WEB_PAGES.USER_PROFILE />} />}
          />
        </>
      )}
      {parseInt(userRoutes?.RoleId) === 2 && (
        <>
          <Route
            path={PATH.USER_DASHBOARD}
            element={<ProtectedRoute element={<WEB_PAGES.USER_DASHBOARD />} />}
          />
          <Route
            path={PATH.USER_BLOG_REQUEST}
            element={
              <ProtectedRoute element={<WEB_PAGES.USER_BLOG_REQUEST />} />
            }
          />
          <Route
            path={PATH.MY_BLOG}
            element={<ProtectedRoute element={<WEB_PAGES.MY_BLOG />} />}
          />
        </>
      )}

      <Route
        path={PATH.GENERATE_BLOG}
        element={<ProtectedRoute element={<WEB_PAGES.GENERATE_BLOG />} />}
      />
    </Routes>
  );
}

export default RouterConfig;
