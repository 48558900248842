import { checkSessionExpiry } from "../../components/genericActions";

function request(actionType, allType) {
  const isExpired = checkSessionExpiry();
  if (isExpired) {
    return {
      type: USER.SET_SESSION_EXPIRE_STATE,
      payload: true,
    };
  } else {
    return {
      type: actionType,
    payload: allType,

    };
  }
  
}
function success(actionType, response) {
  return {
    type: actionType,
    payload: response,
  };
}
function failure(actionType, error) {
  return {
    type: actionType,
    payload: error,
  };
}
const USER = {
  LOGIN_USER_LOADING: " LOGIN_USER_LOADING",
  LOGIN_USER_SUCCESS: " LOGIN_USER_SUCCESS",
  LOGIN_USER_FAILURE: " LOGIN_USER_FAILURE",

  GET_USER_LOADING: "GET_USER_LOADING",
  GET_USER_SUCCESS: "GET_USER_SUCCESS",
  GET_USER_FAILURE: "GET_USER_FAILURE",

  GET_USER_INFO_LOADING: "GET_USER_INFO_LOADING",
  GET_USER_INFO_SUCCESS: "GET_USER_INFO_SUCCESS",
  GET_USER_INFO_FAILURE: "GET_USER_INFO_FAILURE",

  GET_USER_STATS_LOADING: "GET_USER_STATS_LOADING",
  GET_USER_STATS_SUCCESS: "GET_USER_STATS_SUCCESS",
  GET_USER_STATS_FAILURE: "GET_USER_STATS_FAILURE",

  GET_STATS_LOADING: "GET_STATS_LOADING",
  GET_STATS_SUCCESS: "GET_STATS_SUCCESS",
  GET_STATS_FAILURE: "GET_STATS_FAILURE",

  ADD_USER_LOADING: "ADD_USER_LOADING",
  ADD_USER_SUCCESS: "ADD_USER_SUCCESS",
  ADD_USER_FAILURE: "ADD_USER_FAILURE",

  EDIT_USER_LOADING: "EDIT_USER_LOADING",
  EDIT_USER_SUCCESS: "EDIT_USER_SUCCESS",
  EDIT_USER_FAILURE: "EDIT_USER_FAILURE",

  ARCHIVE_USER_LOADING: "ARCHIVE_USER_LOADING",
  ARCHIVE_USER_SUCCESS: "ARCHIVE_USER_SUCCESS",
  ARCHIVE_USER_FAILURE: "ARCHIVE_USER_FAILURE",

  ARCHIVE_BLOGREQUEST_LOADING: "ARCHIVE_BLOGREQUEST_LOADING",
  ARCHIVE_BLOGREQUEST_SUCCESS: "ARCHIVE_BLOGREQUEST_SUCCESS",
  ARCHIVE_BLOGREQUEST_FAILURE: "ARCHIVE_BLOGREQUEST_FAILURE",

  UNARCHIVE_BLOGREQUEST_LOADING: "UNARCHIVE_BLOGREQUEST_LOADING",
  UNARCHIVE_BLOGREQUEST_SUCCESS: "UNARCHIVE_BLOGREQUEST_SUCCESS",
  UNARCHIVE_BLOGREQUEST_FAILURE: "UNARCHIVE_BLOGREQUEST_FAILURE",

  DELETE_USER_LOADING: "DELETE_USER_LOADING",
  DELETE_USER_SUCCESS: "DELETE_USER_SUCCESS",
  DELETE_USER_FAILURE: "DELETE_USER_FAILURE",

  GET_BLOGS_BY_USERID_LOADING: " GET_BLOGS_BY_USERID_LOADING",
  GET_BLOGS_BY_USERID_SUCCESS: " GET_BLOGS_BY_USERID_SUCCESS",
  GET_BLOGS_BY_USERID_FAILURE: " GET_BLOGS_BY_USERID_FAILURE",

  GET_USER_DETAILS_LOADING: " GET_USER_DETAILS_LOADING",
  GET_USER_DETAILS_SUCCESS: " GET_USER_DETAILS_SUCCESS",
  GET_USER_DETAILS_FAILURE: " GET_USER_DETAILS_FAILURE",

  GET_MONTHLY_BLOGS_LOADING: "GET_MONTHLY_BLOGS_LOADING",
  GET_MONTHLY_BLOGS_SUCCESS: "GET_MONTHLY_BLOGS_SUCCESS",
  GET_MONTHLY_BLOGS_FAILURE: "GET_MONTHLY_BLOGS_FAILURE",

  GET_MONTHLY_USERS_LOADING: "GET_MONTHLY_USERS_LOADING",
  GET_MONTHLY_USERS_SUCCESS: "GET_MONTHLY_USERS_SUCCESS",
  GET_MONTHLY_USERS_FAILURE: "GET_MONTHLY_USERS_FAILURE",

  GET_ALL_USERS_BLOGS_COUNTS_LOADING: "GET_ALL_USERS_BLOGS_COUNTS_LOADING",
  GET_ALL_USERS_BLOGS_COUNTS_SUCCESS: "GET_ALL_USERS_BLOGS_COUNTS_SUCCESS",
  GET_ALL_USERS_BLOGS_COUNTS_FAILURE: "GET_ALL_USERS_BLOGS_COUNTS_FAILURE",

  GET_RECENT_BLOGS_LOADING: "GET_RECENT_BLOGS_LOADING",
  GET_RECENT_BLOGS_SUCCESS: "GET_RECENT_BLOGS_SUCCESS",
  GET_RECENT_BLOGS_FAILURE: "GET_RECENT_BLOGS_FAILURE",

  GET_BLOGS_WITHOUTFILTER_LOADING: "GET_BLOGS_WITHOUTFILTER_LOADING",
  GET_BLOGS_WITHOUTFILTER_SUCCESS: "GET_BLOGS_WITHOUTFILTER_SUCCESS",
  GET_BLOGS_WITHOUTFILTER_FAILURE: "GET_BLOGS_WITHOUTFILTER_FAILURE",

  POST_BLOGS_LOADING: "POST_BLOGS_LOADING",
  POST_BLOGS_SUCCESS: "POST_BLOGS_SUCCESS",
  POST_BLOGS_FAILURE: "POST_BLOGS_FAILURE",

  UPDATE_TITLE_LOADING: "UPDATE_TITLE_LOADING",
  UPDATE_TITLE_SUCCESS: "UPDATE_TITLE_SUCCESS",
  UPDATE_TITLE_FAILURE: "UPDATE_TITLE_FAILURE",

  UNDO_BLOGS_LOADING: "UNDO_BLOGS_LOADING",
  UNDO_BLOGS_SUCCESS: "UNDO_BLOGS_SUCCESS",
  UNDO_BLOGS_FAILURE: "UNDO_BLOGS_FAILURE",

  PUSH_TO_CLIENT_LOADING: "PUSH_TO_CLIENT_LOADING",
  PUSH_TO_CLIENT_SUCCESS: "PUSH_TO_CLIENT_SUCCESS",
  PUSH_TO_CLIENT_FAILURE: "PUSH_TO_CLIENT_FAILURE",

  SAVE_AS_DRAFT_LOADING: "SAVE_AS_DRAFT_LOADING",
  SAVE_AS_DRAFT_SUCCESS: "SAVE_AS_DRAFT_SUCCESS",
  SAVE_AS_DRAFT_FAILURE: "SAVE_AS_DRAFT_FAILURE",

  GET_TITLE_LOADING: "GET_TITLE_LOADING",
  GET_TITLE_SUCCESS: "GET_TITLE_SUCCESS",
  GET_TITLE_FAILURE: "GET_TITLE_FAILURE",

  REGENERATE_TITLE_LOADING: "REGENERATE_TITLE_LOADING",
  REGENERATE_TITLE_SUCCESS: "REGENERATE_TITLE_SUCCESS",
  REGENERATE_TITLE_FAILURE: "REGENERATE_TITLE_FAILURE",

  GET_DRAFT_BLOG_LOADING: "GET_DRAFT_BLOG_LOADING",
  GET_DRAFT_BLOG_SUCCESS: "GET_DRAFT_BLOG_SUCCESS",
  GET_DRAFT_BLOG_FAILURE: "GET_DRAFT_BLOG_FAILURE",

  GET_BLOG_HISTORY_LOADING: "GET_BLOG_HISTORY_LOADING",
  GET_BLOG_HISTORY_SUCCESS: "GET_BLOG_HISTORY_SUCCESS",
  GET_BLOG_HISTORY_FAILURE: "GET_BLOG_HISTORY_FAILURE",

  GET_USER_FILTER_LOADING: "GET_USER_FILTER_LOADING",
  GET_USER_FILTER_SUCCESS: "GET_USER_FILTER_SUCCESS",
  GET_USER_FILTER_FAILURE: "GET_USER_FILTER_FAILURE",

  GET_BLOG_DATA_FILTER_LOADING: "GET_BLOG_DATA_FILTER_LOADING",
  GET_BLOG_DATA_FILTER_SUCCESS: "GET_BLOG_DATA_FILTER_SUCCESS",
  GET_BLOG_DATA_FILTER_FAILURE: "GET_USER_FILTER_FAILURE",

  UPDATE_BLOG_LOADING: "UPDATE_BLOG_LOADING",
  UPDATE_BLOG_SUCCESS: "UPDATE_BLOG_SUCCESS",
  UPDATE_BLOG_FAILURE: "UPDATE_BLOG_FAILURE",

  DELETE_REQUEST_LOADING: "DELETE_REQUEST_LOADING",
  DELETE_REQUEST_SUCCESS: "DELETE_REQUEST_SUCCESS",
  DELETE_REQUEST_FAILURE: "DELETE_REQUEST_FAILURE",

  GET_ALL_NOTIFICATIONS_LOADING: "GET_ALL_NOTIFICATIONS_LOADING",
  GET_ALL_NOTIFICATIONS_SUCCESS: "GET_ALL_NOTIFICATIONS_SUCCESS",
  GET_ALL_NOTIFICATIONS_FAILURE: "GET_ALL_NOTIFICATIONS_FAILURE",

  USER_UNARCHIVE_LOADING: "USER_UNARCHIVE_LOADING",
  USER_UNARCHIVE_SUCCESS: "USER_UNARCHIVE_SUCCESS",
  USER_UNARCHIVE_FAILURE: "USER_UNARCHIVE_FAILURE",

  REQUEST_UNARCHIVE_LOADING: "REQUEST_UNARCHIVE_LOADING",
  REQUEST_UNARCHIVE_SUCCESS: "REQUEST_UNARCHIVE_SUCCESS",
  REQUEST_UNARCHIVE_FAILURE: "REQUEST_UNARCHIVE_FAILURE",

  FIRST_TIEM_VISIT_LOADING: "FIRST_TIEM_VISIT_LOADING",
  FIRST_TIEM_VISIT_SUCCESS: "FIRST_TIEM_VISIT_SUCCESS",
  FIRST_TIEM_VISIT_FAILURE: "FIRST_TIEM_VISIT_FAILURE",

  SET_SESSION_EXPIRE_STATE: "SET_SESSION_EXPIRE_STATE",
};

const GENERATEBLOG = {
  GENERATE_BLOG_LOADING: " GENERATE_BLOG_LOADING",
  GENERATE_BLOG_SUCCESS: " GENERATE_BLOG_SUCCESS",
  GENERATE_BLOG_FAILURE: " GENERATE_BLOG_FAILURE",
};

const BLOGS = {
  REQUEST_BLOG_LOADING: " REQUEST_BLOG_LOADING",
  REQUEST_BLOG_SUCCESS: " REQUEST_BLOG_SUCCESS",
  REQUEST_BLOG_FAILURE: " REQUEST_BLOG_FAILURE",

  GET_ALL_BLOG_LOADING: " GET_ALL_BLOG_LOADING",
  GET_ALL_BLOG_SUCCESS: " GET_ALL_BLOG_SUCCESS",
  GET_ALL_BLOG_FAILURE: " GET_ALL_BLOG_FAILURE",

  GET_REQUESTED_BLOG_LOADING: " GET_REQUESTED_BLOG_LOADING",
  GET_REQUESTED_BLOG_SUCCESS: " GET_REQUESTED_BLOG_SUCCESS",
  GET_REQUESTED_BLOG_FAILURE: " GET_REQUESTED_BLOG_FAILURE",

  GET_REQUESTED_BLOG_USER_DATA_LOADING: " GET_REQUESTED_BLOG_USER_DATA_LOADING",
  GET_REQUESTED_BLOG_USER_DATA_SUCCESS: " GET_REQUESTED_BLOG_USER_DATA_SUCCESS",
  GET_REQUESTED_BLOG_USER_DATA_FAILURE: " GET_REQUESTED_BLOG_USER_DATA_FAILURE",

  CHANGE_REQUESTED_BLOG_STATUS_LOADING: " CHANGE_REQUESTED_BLOG_STATUS_LOADING",
  CHANGE_REQUESTED_BLOG_STATUS_SUCCESS: " CHANGE_REQUESTED_BLOG_STATUS_SUCCESS",
  CHANGE_REQUESTED_BLOG_STATUS_FAILURE: " CHANGE_REQUESTED_BLOG_STATUS_FAILURE",

  GET_USER_BLOGS_LOADING: "GET_USER_BLOGS_LOADING",
  GET_USER_BLOGS_SUCCESS: "GET_USER_BLOGS_SUCCESS",
  GET_USER_BLOGS_FAILURE: "GET_USER_BLOGS_FAILURE",

  DELETE_BLOGS_LOADING: "DELETE_BLOGS_LOADING",
  DELETE_BLOGS_SUCCESS: "DELETE_BLOGS_SUCCESS",
  DELETE_BLOGS_FAILURE: "DELETE_BLOGS_FAILURE",
};

export { request, success, failure, USER, GENERATEBLOG, BLOGS };
