import { checkSessionExpiry } from "../../components/genericActions";
import { BLOGS, USER } from "../actions/utilities";
const INITIAL_STATE = {
  userLoginLoading: false,
  userLoginSuccess: false,
  userLoginFailure: false,
  userLoginError: null,
  userLoginList: [],

  addUserLoading: false,
  addUserSuccess: false,
  addUserFailure: false,
  addUserError: null,
  addUserData: [],

  editUserLoading: false,
  editUserSuccess: false,
  editUserFailure: false,
  editUserError: null,

  archiveUserLoading: false,
  archiveUserSuccess: false,
  archiveUserFailure: false,
  archiveUserError: null,

  archiveBlogLoading: false,
  archiveBlogSuccess: false,
  archiveBlogFailure: false,
  archiveBlogError: null,
  archiveBlogData: null,

  unArchiveBlogLoading: false,
  unArchiveBlogSuccess: false,
  unArchiveBlogFailure: false,
  unArchiveBlogError: null,
  unArchiveBlogData: null,

  deleteUserLoading: false,
  deleteUserSuccess: false,
  deleteUserFailure: false,
  deleteUserError: null,

  deleteBlogLoading: false,
  deleteBlogSuccess: false,
  deleteBlogFailure: false,
  deleteBlogError: null,

  getBlogsByUserIdLoading: false,
  getBlogsByUserIdSuccess: false,
  getBlogsByUserIdFailure: false,
  getBlogsByUserIdError: null,

  getUserLoading: false,
  getUserSuccess: false,
  getUserFailure: false,
  getUserError: null,
  userList: [],

  getUserInfoLoading: false,
  getUserInfoSuccess: false,
  getUserInfoFailure: false,
  getUserInfoError: null,
  userInfoList: [],

  getUserBlogLoading: false,
  getUserBlogSuccess: false,
  getUserBlogFailure: false,
  getUserBlogError: null,
  userBlogList: [],

  getUserStatsLoading: false,
  getUserStatsSuccess: false,
  getUserStatsFailure: false,
  getUserStatsError: null,
  userStats: null,

  getStatsLoading: false,
  getStatsSuccess: false,
  getStatsFailure: false,
  getStatsError: null,
  allStats: null,

  getUserDetailsLoading: false,
  getUserDetailsSuccess: false,
  getUserDetailsFailure: false,
  getUserDetailsError: null,
  getUserDetails: null,

  getLatestBlogsLoading: false,
  getLatestBlogsSuccess: false,
  getLatestBlogsFailure: false,
  getLatestBlogsError: null,
  getLatestBlogs: null,

  getBlogsWithoutFilterLoading: false,
  getBlogsWithoutFilterSuccess: false,
  getBlogsWithoutFilterFailure: false,
  getBlogsWithoutFilterError: null,
  getBlogsWithoutFilter: null,

  getTotalRecordsLoading: false,
  getTotalRecordsSuccess: false,
  getTotalRecordsFailure: false,
  getTotalRecordsError: null,
  getTotalRecords: null,

  getMonthlyUsersLoading: false,
  getMonthlyUsersSuccess: false,
  getMonthlyUsersFailure: false,
  getMonthlyUsersError: null,
  getMonthlyUsers: null,

  getMonthlyBlogsLoading: false,
  getMonthlyBlogsSuccess: false,
  getMonthlyBlogsFailure: false,
  getMonthlyBlogsError: null,
  getMonthlyBlogs: null,

  blogPostLoading: false,
  blogPostSuccess: false,
  blogPostFailure: false,
  blogPostError: null,
  blogPost: null,
  blogPostData: [],

  undoBlogLoading: false,
  undoBlogSuccess: false,
  undoBlogFailure: false,
  undoBlogError: null,
  undoBlogData: [],

  pushToClientLoading: false,
  pushToClientSuccess: false,
  pushToClientFailure: false,
  pushToClientError: null,
  pushToClientData: null,

  blogtodraftLoading: false,
  blogtodraftSuccess: false,
  blogtodraftFailure: false,
  blogtodraftError: null,
  blogtodraftData: null,

  getTitlesLoading: false,
  getTitlesSuccess: false,
  getTitlesFailure: false,
  getTitesError: null,
  getTitlesData: null,

  regenrateTitleLoading: false,
  regenrateTitleSuccess: false,
  regenrateTitleFailure: false,
  regenrateTitleError: null,
  regenrateTitleData: null,

  updateTitleLoading: false,
  updateTitleSuccess: false,
  updateTitleFailure: false,
  updateTitleError: null,
  updateTitleData: null,

  getDraftBlogLoading: false,
  getDraftBlogSuccess: false,
  getDraftBlogFailure: false,
  getDraftBlogError: null,
  getDraftBlogData: null,

  getBlogHistoryLoading: false,
  getBlogHistorySuccess: false,
  getBlogHistoryFailure: false,
  getBlogHistoryError: null,

  getUserFilterLoading: false,
  getUserFilterSuccess: false,
  getUserFilterFailure: false,
  getUserFilterError: null,

  getBlogDataFilterLoading: false,
  getBlogDataFilterSuccess: false,
  getBlogDataFilterFailure: false,
  getBlogDataFilterError: null,

  updateBlogLoading: false,
  updateBlogSuccess: false,
  updateBlogFailure: false,
  updateBlogError: null,

  deleteRequestLoading: false,
  deleteRequestSuccess: false,
  deleteRequestFailure: false,
  deleteRequestError: null,

  userUnarchiveLoading: false,
  userUnarchiveSuccess: false,
  userUnarchiveFailure: false,
  userUnarchiveError: null,
  userUnarchiveData: null,

  requestUnarchiveLoading: false,
  requestUnarchiveSuccess: false,
  requestUnarchiveFailure: false,
  requestUnarchiveError: null,
  requestUnarchiveData: null,

  getAllNotificationLoading: false,
  getAllNotificationSuccess: false,
  getAllNotificationFailure: false,
  getAllNotificationError: null,
  getAllNotificationData: null,

  changeFirstTimeVisitLoading: false,
  changeFirstTimeVisitSuccess: false,
  changeFirstTimeVisitFailure: false,
  changeFirstTimeVisitError: null,
  changeFirstTimeVisitData: null,
  sessionExpire: checkSessionExpiry(),
};
export const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case USER.FIRST_TIEM_VISIT_LOADING:
      return {
        ...state,
        changeFirstTimeVisitLoading: true,
        changeFirstTimeVisitSuccess: false,
        changeFirstTimeVisitFailure: false,
        changeFirstTimeVisitError: null,
      };
    case USER.FIRST_TIEM_VISIT_SUCCESS:
      return {
        ...state,
        changeFirstTimeVisitLoading: false,
        changeFirstTimeVisitSuccess: true,
        changeFirstTimeVisitFailure: false,
        changeFirstTimeVisitError: null,
        changeFirstTimeVisitData: action.payload,
      };
    case USER.FIRST_TIEM_VISIT_FAILURE:
      return {
        ...state,
        changeFirstTimeVisitLoading: false,
        changeFirstTimeVisitSuccess: false,
        changeFirstTimeVisitFailure: true,
        changeFirstTimeVisitError: action.payload,
      };
    case USER.GET_ALL_NOTIFICATIONS_LOADING:
      return {
        ...state,
        getAllNotificationLoading: true,
        getAllNotificationSuccess: false,
        getAllNotificationFailure: false,
        getAllNotificationError: null,
      };
    case USER.GET_ALL_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        getAllNotificationLoading: false,
        getAllNotificationSuccess: true,
        getAllNotificationFailure: false,
        getAllNotificationError: null,
        getAllNotificationData: action.payload,
      };
    case USER.GET_ALL_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        getAllNotificationLoading: false,
        getAllNotificationSuccess: false,
        getAllNotificationFailure: true,
        getAllNotificationError: action.payload,
      };
    case USER.REQUEST_UNARCHIVE_LOADING:
      return {
        ...state,
        requestUnarchiveLoading: true,
        requestUnarchiveSuccess: false,
        requestUnarchiveFailure: false,
        requestUnarchiveError: null,
      };
    case USER.REQUEST_UNARCHIVE_SUCCESS:
      return {
        ...state,
        requestUnarchiveLoading: false,
        requestUnarchiveSuccess: true,
        requestUnarchiveFailure: false,
        requestUnarchiveError: null,
        requestUnarchiveData: action.payload,
      };
    case USER.REQUEST_UNARCHIVE_FAILURE:
      return {
        ...state,
        requestUnarchiveLoading: false,
        requestUnarchiveSuccess: false,
        requestUnarchiveFailure: true,
        requestUnarchiveError: action.payload,
      };
    case USER.USER_UNARCHIVE_LOADING:
      return {
        ...state,
        userUnarchiveLoading: true,
        userUnarchiveSuccess: false,
        userUnarchiveFailure: false,
        userUnarchiveError: null,
      };
    case USER.USER_UNARCHIVE_SUCCESS:
      return {
        ...state,
        userUnarchiveLoading: false,
        userUnarchiveSuccess: true,
        userUnarchiveFailure: false,
        userUnarchiveError: null,
        userUnarchiveData: action.payload,
      };
    case USER.USER_UNARCHIVE_FAILURE:
      return {
        ...state,
        userUnarchiveLoading: false,
        userUnarchiveSuccess: false,
        userUnarchiveFailure: true,
        userUnarchiveError: action.payload,
      };
  
    case USER.DELETE_REQUEST_LOADING:
      return {
        ...state,
        deleteRequestLoading: true,
        deleteRequestSuccess: false,
        deleteRequestFailure: false,
        deleteRequestError: null,
      };
    case USER.DELETE_REQUEST_SUCCESS:
      return {
        ...state,
        deleteRequestLoading: false,
        deleteRequestSuccess: true,
        deleteRequestFailure: false,
        deleteRequestError: null,
        deleteRequestData: action.payload,
      };
    case USER.DELETE_REQUEST_FAILURE:
      return {
        ...state,
        deleteRequestLoading: false,
        deleteRequestSuccess: false,
        deleteRequestFailure: true,
        deleteRequestError: action.payload,
      };
  

      case USER.GET_DRAFT_BLOG_LOADING:
      return {
        ...state,
        getDraftBlogLoading: true,
        getDraftBlogSuccess: false,
        getDraftBlogFailure: false,
        getDraftBlogError: null,
      };
    case USER.GET_DRAFT_BLOG_SUCCESS:
      return {
        ...state,
        getDraftBlogLoading: false,
        getDraftBlogSuccess: true,
        getDraftBlogFailure: false,
        getDraftBlogError: null,
        getDraftBlogData: action.payload,
      };
    case USER.GET_DRAFT_BLOG_FAILURE:
      return {
        ...state,
        getDraftBlogLoading: false,
        getDraftBlogSuccess: false,
        getDraftBlogFailure: true,
        getDraftBlogError: action.payload,
      };

    case USER.SAVE_AS_DRAFT_LOADING:
      return {
        ...state,
        blogtodraftLoading: true,
        blogtodraftSuccess: false,
        blogtodraftFailure: false,
        blogtodraftError: null,
      };
    case USER.SAVE_AS_DRAFT_SUCCESS:
      return {
        ...state,
        blogtodraftLoading: false,
        blogtodraftSuccess: true,
        blogtodraftFailure: false,
        blogtodraftError: null,
        blogtodraftData: action.payload,
      };
    case USER.SAVE_AS_DRAFT_FAILURE:
      return {
        ...state,
        blogtodraftLoading: false,
        blogtodraftSuccess: false,
        blogtodraftFailure: true,
        blogtodraftError: action.payload,
      };

    case USER.REGENERATE_TITLE_LOADING:
      return {
        ...state,
        regenrateTitleLoading: true,
        regenrateTitleSuccess: false,
        regenrateTitleFailure: false,
        regenrateTitleError: null,
      };
    case USER.REGENERATE_TITLE_SUCCESS:
      return {
        ...state,
        regenrateTitleLoading: false,
        regenrateTitleSuccess: true,
        regenrateTitleFailure: false,
        regenrateTitleError: null,
        regenrateTitleData: action.payload,
      };
    case USER.REGENERATE_TITLE_FAILURE:
      return {
        ...state,
        regenrateTitleLoading: false,
        regenrateTitleSuccess: false,
        regenrateTitleFailure: true,
        regenrateTitleError: action.payload,
      };

    case USER.UPDATE_TITLE_LOADING:
      return {
        ...state,
        updateTitleLoading: true,
        updateTitleSuccess: false,
        updateTitleFailure: false,
        updateTitleError: null,
      };
    case USER.UPDATE_TITLE_SUCCESS:
      return {
        ...state,
        updateTitleLoading: false,
        updateTitleSuccess: true,
        updateTitleFailure: false,
        updateTitleError: null,
        updateTitleData: action.payload,
      };
    case USER.UPDATE_TITLE_FAILURE:
      return {
        ...state,
        updateTitleLoading: false,
        updateTitleSuccess: false,
        updateTitleFailure: true,
        updateTitleError: action.payload,
      };
    case USER.GET_TITLE_LOADING:
      return {
        ...state,
        getTitlesLoading: true,
        getTitlesSuccess: false,
        getTitlesFailure: false,
        getTitlesError: null,
      };
    case USER.GET_TITLE_SUCCESS:
      return {
        ...state,
        getTitlesLoading: false,
        getTitlesSuccess: true,
        getTitlesFailure: false,
        getTitlesError: null,
        getTitlesData: action.payload,
      };
    case USER.GET_TITLE_FAILURE:
      return {
        ...state,
        getTitlesLoading: false,
        getTitlesSuccess: false,
        getTitlesFailure: true,
        getTitlesError: action.payload,
      };

    case USER.PUSH_TO_CLIENT_LOADING:
      return {
        ...state,
        pushToClientLoading: true,
        pushToClientSuccess: false,
        pushToClientFailure: false,
        pushToClientError: null,
      };
    case USER.PUSH_TO_CLIENT_SUCCESS:
      return {
        ...state,
        pushToClientLoading: false,
        pushToClientSuccess: true,
        pushToClientFailure: false,
        pushToClientError: null,
        pushToClientData: action.payload,
      };
    case USER.PUSH_TO_CLIENT_FAILURE:
      return {
        ...state,
        pushToClientLoading: false,
        pushToClientSuccess: false,
        pushToClientFailure: true,
        pushToClientError: action.payload,
      };

    case USER.UNDO_BLOGS_LOADING:
      return {
        ...state,
        undoBlogLoading: true,
        undoBlogSuccess: false,
        undoBlogFailure: false,
        undoBlogError: null,
      };
    case USER.UNDO_BLOGS_SUCCESS:
      return {
        ...state,
        undoBlogLoading: false,
        undoBlogSuccess: true,
        undoBlogFailure: false,
        undoBlogError: null,
        undoBlogData: action.payload,
      };
    case USER.UNDO_BLOGS_FAILURE:
      return {
        ...state,
        undoBlogLoading: false,
        undoBlogSuccess: false,
        undoBlogFailure: true,
        undoBlogError: action.payload,
      };

    case USER.POST_BLOGS_LOADING:
      return {
        ...state,
        blogPostLoading: true,
        blogPostSuccess: false,
        blogPostFailure: false,
        blogPostError: null,
      };
    case USER.POST_BLOGS_SUCCESS:
      return {
        ...state,
        blogPostLoading: false,
        blogPostSuccess: true,
        blogPostFailure: false,
        blogPostError: null,
        blogPostData: action.payload,
      };
    case USER.POST_BLOGS_FAILURE:
      return {
        ...state,
        blogPostLoading: false,
        blogPostSuccess: false,
        blogPostFailure: true,
        blogPostError: action.payload,
      };

    case USER.LOGIN_USER_LOADING:
      return {
        ...state,
        userLoginLoading: true,
        userLoginSuccess: false,
        userLoginFailure: false,
        userLoginError: null,
      };
    case USER.LOGIN_USER_SUCCESS:
      return {
        ...state,
        userLoginLoading: false,
        userLoginSuccess: true,
        userLoginFailure: false,
        userLoginError: null,
        userLoginData: action.payload,
      };
    case USER.LOGIN_USER_FAILURE:
      return {
        ...state,
        userLoginLoading: false,
        userLoginSuccess: false,
        userLoginFailure: true,
        userLoginError: action.payload,
      };

    case USER.ADD_USER_LOADING:
      return {
        ...state,
        addUserLoading: true,
        addUserSuccess: false,
        addUserFailure: false,
        addUserError: null,
      };
    case USER.ADD_USER_SUCCESS:
      let respnse = action.payload;
      let adduser = state.userList;
      adduser?.unshift(respnse);
      return {
        ...state,
        addUserLoading: false,
        addUserSuccess: true,
        addUserFailure: false,
        addUserError: null,
        addUserData: adduser,
      };
    case USER.ADD_USER_FAILURE:
      return {
        ...state,
        addUserLoading: false,
        addUserSuccess: false,
        addUserFailure: true,
        addUserError: action.payload,
      };
    case USER.GET_USER_LOADING:
      return {
        ...state,
        getUserLoading: true,
        getUserSuccess: false,
        getUserFailure: false,
        getUserError: null,
      };
    case USER.GET_USER_SUCCESS:
      return {
        ...state,
        getUserLoading: false,
        getUserSuccess: true,
        getUserFailure: false,
        getUserError: null,
        userList: action.payload,
      };
    case USER.GET_USER_FAILURE:
      return {
        ...state,
        getUserLoading: false,
        getUserSuccess: false,
        getUserFailure: true,
        getUserError: action.payload,
      };
    case USER.GET_USER_INFO_LOADING:
      return {
        ...state,
        getUserInfoLoading: true,
        getUserInfoSuccess: false,
        getUserInfoFailure: false,
        getUserInfoError: null,
      };
    case USER.GET_USER_INFO_SUCCESS:
      return {
        ...state,
        getUserInfoLoading: false,
        getUserInfoSuccess: true,
        getUserInfoFailure: false,
        getUserInfoError: null,
        userInfoList: action.payload,
      };
    case USER.GET_USER_INFO_FAILURE:
      return {
        ...state,
        getUserLoading: false,
        getUserSuccess: false,
        getUserFailure: true,
        getUserError: action.payload,
      };
    case USER.EDIT_USER_LOADING:
      return {
        ...state,
        editUserLoading: true,
        editUserSuccess: false,
        editUserFailure: false,
        editUserError: null,
      };
    case USER.EDIT_USER_SUCCESS:
      return {
        ...state,
        editUserLoading: false,
        editUserSuccess: true,
        editUserFailure: false,
        editUserError: null,
        generatedBlog: action.payload,
      };
    case USER.EDIT_USER_FAILURE:
      return {
        ...state,
        editUserLoading: false,
        editUserSuccess: false,
        editUserFailure: true,
        editUserError: action.payload,
      };

    case USER.ARCHIVE_USER_LOADING:
      return {
        ...state,
        archiveUserLoading: true,
        archiveUserSuccess: false,
        archiveUserFailure: false,
        archiveUserError: null,
      };
    case USER.ARCHIVE_USER_SUCCESS:
      return {
        ...state,
        archiveUserLoading: false,
        archiveUserSuccess: true,
        archiveUserFailure: false,
        archiveUserError: null,
        generatedBlog: action.payload,
      };
    case USER.ARCHIVE_USER_FAILURE:
      return {
        ...state,
        archiveUserLoading: false,
        archiveUserSuccess: false,
        archiveUserFailure: true,
        archiveUserError: action.payload,
      };

    case USER.ARCHIVE_BLOGREQUEST_LOADING:
      return {
        ...state,
        archiveBlogLoading: true,
        archiveBlogSuccess: false,
        archiveBlogFailure: false,
        archiveBlogError: null,
      };
    case USER.ARCHIVE_BLOGREQUEST_SUCCESS:
      return {
        ...state,
        archiveBlogLoading: false,
        archiveBlogSuccess: true,
        archiveBlogFailure: false,
        archiveBlogError: null,
        archiveBlogData: action.payload,
      };
    case USER.ARCHIVE_BLOGREQUEST_FAILURE:
      return {
        ...state,
        archiveBlogLoading: false,
        archiveBlogSuccess: false,
        archiveBlogFailure: true,
        archiveBlogError: action.payload,
      };
    case USER.UNARCHIVE_BLOGREQUEST_LOADING:
      return {
        ...state,
        unArchiveBlogLoading: true,
        unArchiveBlogSuccess: false,
        unArchiveBlogFailure: false,
        unArchiveBlogError: null,
      };
    case USER.UNARCHIVE_BLOGREQUEST_SUCCESS:
      return {
        ...state,
        unArchiveBlogLoading: false,
        unArchiveBlogSuccess: true,
        unArchiveBlogFailure: false,
        unArchiveBlogError: null,
        unArchiveBlogData: action.payload,
      };
    case USER.UNARCHIVE_BLOGREQUEST_FAILURE:
      return {
        ...state,
        unArchiveBlogLoading: false,
        unArchiveBlogSuccess: false,
        unArchiveBlogFailure: true,
        unArchiveBlogError: action.payload,
      };

    case USER.DELETE_USER_LOADING:
      return {
        ...state,
        deleteUserLoading: true,
        deleteUserSuccess: false,
        deleteUserFailure: false,
        deleteUserError: null,
      };
    case USER.DELETE_USER_SUCCESS:
      let deleteId = action.payload;
      let list = state.userList;
      let result = list.filter((item) => item.id !== deleteId);
      return {
        ...state,
        deleteUserLoading: false,
        deleteUserSuccess: true,
        deleteUserFailure: false,
        deleteUserError: null,
        userList: result,
      };
    case USER.DELETE_USER_FAILURE:
      return {
        ...state,
        deleteUserLoading: false,
        deleteUserSuccess: false,
        deleteUserFailure: true,
        deleteUserError: action.payload,
      };
    case USER.GET_BLOGS_BY_USERID_LOADING:
      return {
        ...state,
        getBlogsByUserIdLoading: true,
        getBlogsByUserIdSuccess: false,
        getBlogsByUserIdFailure: false,
        getBlogsByUserIdError: null,
      };
    case USER.GET_BLOGS_BY_USERID_SUCCESS:
      return {
        ...state,
        getBlogsByUserIdLoading: false,
        getBlogsByUserIdSuccess: true,
        getBlogsByUserIdFailure: false,
        getBlogsByUserIdError: null,
        generatedBlog: action.payload,
      };
    case USER.GET_BLOGS_BY_USERID_FAILURE:
      return {
        ...state,
        getBlogsByUserIdLoading: false,
        getBlogsByUserIdSuccess: false,
        getBlogsByUserIdFailure: false,
        getBlogsByUserIdError: action.payload,
      };
    case USER.GET_USER_STATS_LOADING:
      return {
        ...state,
        getUserStatsLoading: true,
        getUserStatsSuccess: false,
        getUserStatsFailure: false,
        getUserStatsError: null,
      };
    case USER.GET_USER_STATS_SUCCESS:
      return {
        ...state,
        getUserStatsLoading: false,
        getUserStatsSuccess: true,
        getUserStatsFailure: false,
        getUserStatsError: null,
        userStats: action.payload,
      };
    case USER.GET_USER_STATS_FAILURE:
      return {
        ...state,
        getUserStatsLoading: false,
        getUserStatsSuccess: false,
        getUserStatsFailure: true,
        getUserStatsError: action.payload,
      };

    case USER.GET_STATS_LOADING:
      return {
        ...state,
        getStatsLoading: true,
        getStatsSuccess: false,
        getStatsFailure: false,
        getStatsError: null,
      };
    case USER.GET_STATS_SUCCESS:
      return {
        ...state,
        getStatsLoading: false,
        getStatsSuccess: true,
        getStatsFailure: false,
        getStatsError: null,
        allStats: action.payload,
      };
    case USER.GET_STATS_FAILURE:
      return {
        ...state,
        getStatsLoading: false,
        getStatsSuccess: false,
        getStatsFailure: true,
        getStatsError: action.payload,
      };
    case BLOGS.GET_USER_BLOGS_LOADING:
      return {
        ...state,
        getUserBlogLoading: true,
        getUserBlogSuccess: false,
        getUserBlogFailure: false,
        getUserBlogError: null,
      };
    case BLOGS.GET_USER_BLOGS_SUCCESS:
      return {
        ...state,
        getUserBlogLoading: false,
        getUserBlogSuccess: true,
        getUserBlogFailure: false,
        getUserBlogError: null,
        userBlogList: action.payload,
      };
    case BLOGS.GET_USER_BLOGS_FAILURE:
      return {
        ...state,
        getUserBlogLoading: false,
        getUserBlogSuccess: false,
        getUserBlogFailure: true,
        getUserBlogError: action.payload,
      };
    case USER.GET_USER_DETAILS_LOADING:
      return {
        ...state,
        getUserDetailsLoading: true,
        getUserDetailsSuccess: false,
        getUserDetailsFailure: false,
        getUserDetailsError: null,
      };
    case USER.GET_USER_DETAILS_SUCCESS:
      return {
        ...state,
        getUserDetailsLoading: false,
        getUserDetailsSuccess: true,
        getUserDetailsFailure: false,
        getUserDetailsError: null,
        getUserDetails: action.payload,
      };
    case USER.GET_USER_DETAILS_FAILURE:
      return {
        ...state,
        getUserDetailsLoading: false,
        getUserDetailsSuccess: false,
        getUserDetailsFailure: true,
        getUserDetailsError: action.payload,
      };
    case BLOGS.DELETE_BLOGS_LOADING:
      return {
        ...state,
        deleteBlogLoading: true,
        deleteBlogSuccess: false,
        deleteBlogFailure: false,
        deleteBlogError: null,
      };
    case BLOGS.DELETE_BLOGS_SUCCESS:
      let deleteBlogId = action.payload;
      let listBlog = state.userBlogList;
      let resultBlog = listBlog.filter((item) => item.id !== deleteBlogId);
      return {
        ...state,
        deleteBlogLoading: false,
        deleteBlogSuccess: true,
        deleteBlogFailure: false,
        deleteBlogError: null,
        userBlogList: resultBlog,
      };
    case BLOGS.DELETE_BLOGS_FAILURE:
      return {
        ...state,
        deleteUserLoading: false,
        deleteUserSuccess: false,
        deleteUserFailure: true,
        deleteUserError: action.payload,
      };
    case USER.GET_MONTHLY_BLOGS_LOADING:
      return {
        ...state,
        getMonthlyBlogsLoading: true,
        getMonthlyBlogsSuccess: false,
        getMonthlyBlogsFailure: false,
        getMonthlyBlogsError: null,
      };
    case USER.GET_MONTHLY_BLOGS_SUCCESS:
      return {
        ...state,
        getMonthlyBlogsLoading: false,
        getMonthlyBlogsSuccess: true,
        getMonthlyBlogsFailure: false,
        getMonthlyBlogsError: null,
        getMonthlyBlogs: action.payload,
      };
    case USER.GET_MONTHLY_BLOGS_FAILURE:
      return {
        ...state,
        getMonthlyBlogsLoading: false,
        getMonthlyBlogsSuccess: false,
        getMonthlyBlogsFailure: true,
        getMonthlyBlogsError: action.payload,
      };

    case USER.GET_MONTHLY_USERS_LOADING:
      return {
        ...state,
        getMonthlyUsersLoading: true,
        getMonthlyUsersSuccess: false,
        getMonthlyUsersFailure: false,
        getMonthlyUsersError: null,
      };
    case USER.GET_MONTHLY_USERS_SUCCESS:
      return {
        ...state,
        getMonthlyUsersLoading: false,
        getMonthlyUsersSuccess: true,
        getMonthlyUsersFailure: false,
        getMonthlyUsersError: null,
        getMonthlyUsers: action.payload,
      };
    case USER.GET_MONTHLY_USERS_FAILURE:
      return {
        ...state,
        getMonthlyUsersLoading: false,
        getMonthlyUsersSuccess: false,
        getMonthlyUsersFailure: true,
        getMonthlyUsersError: action.payload,
      };

    case USER.GET_ALL_USERS_BLOGS_COUNTS_LOADING:
      return {
        ...state,
        getTotalRecordsLoading: true,
        getTotalRecordsSuccess: false,
        getTotalRecordsFailure: false,
        getTotalRecordsError: null,
      };
    case USER.GET_ALL_USERS_BLOGS_COUNTS_SUCCESS:
      return {
        ...state,
        getTotalRecordsLoading: false,
        getTotalRecordsSuccess: true,
        getTotalRecordsFailure: false,
        getTotalRecordsError: null,
        getTotalRecords: action.payload,
      };
    case USER.GET_ALL_USERS_BLOGS_COUNTS_FAILURE:
      return {
        ...state,
        getTotalRecordsLoading: false,
        getTotalRecordsSuccess: false,
        getTotalRecordsFailure: true,
        getTotalRecordsError: action.payload,
      };
    case USER.GET_BLOGS_WITHOUTFILTER_LOADING:
      return {
        ...state,
        getBlogsWithoutFilterLoading: true,
        getBlogsWithoutFilterSuccess: false,
        getBlogsWithoutFilterFailure: false,
        getBlogsWithoutFilterError: null,
      };
    case USER.GET_BLOGS_WITHOUTFILTER_SUCCESS:
      return {
        ...state,
        getBlogsWithoutFilterLoading: false,
        getBlogsWithoutFilterSuccess: true,
        getBlogsWithoutFilterFailure: false,
        getBlogsWithoutFilterError: null,
        getBlogsWithoutFilter: action.payload,
      };
    case USER.GET_BLOGS_WITHOUTFILTER_FAILURE:
      return {
        ...state,
        getBlogsWithoutFilterLoading: false,
        getBlogsWithoutFilterSuccess: false,
        getBlogsWithoutFilterFailure: true,
        getBlogsWithoutFilterError: action.payload,
      };
    case USER.GET_RECENT_BLOGS_LOADING:
      return {
        ...state,
        getLatestBlogsLoading: true,
        getLatestBlogsSuccess: false,
        getLatestBlogsFailure: false,
        getLatestBlogsError: null,
      };
    case USER.GET_RECENT_BLOGS_SUCCESS:
      return {
        ...state,
        getLatestBlogsLoading: false,
        getLatestBlogsSuccess: true,
        getLatestBlogsFailure: false,
        getLatestBlogsError: null,
        getLatestBlogs: action.payload,
      };
    case USER.GET_RECENT_BLOGS_FAILURE:
      return {
        ...state,
        getLatestBlogsLoading: false,
        getLatestBlogsSuccess: false,
        getLatestBlogsFailure: true,
        getLatestBlogsError: action.payload,
      };
    case USER.GET_BLOG_HISTORY_LOADING:
      return {
        ...state,
        getBlogHistoryLoading: true,
        getBlogHistorySuccess: false,
        getBlogHistoryFailure: false,
        getBlogHistoryError: null,
        blogPostData: [],
      };
    case USER.GET_BLOG_HISTORY_SUCCESS:
      return {
        ...state,
        getBlogHistoryLoading: false,
        getBlogHistorySuccess: true,
        getBlogHistoryFailure: false,
        getBlogHistoryError: null,
        blogPostData: action.payload,
      };
    case USER.GET_BLOG_HISTORY_FAILURE:
      return {
        ...state,
        getBlogHistoryLoading: false,
        getBlogHistorySuccess: false,
        getBlogHistoryFailure: true,
        getBlogHistoryError: action.payload,
      };

    case USER.GET_USER_FILTER_LOADING:
      return {
        ...state,
        getUserFilterLoading: true,
        getUserFilterSuccess: false,
        getUserFilterFailure: false,
        getUserFilterError: null,
        // getMonthlyUsers: [],
      };
    case USER.GET_USER_FILTER_SUCCESS:
      return {
        ...state,
        getUserFilterLoading: false,
        getUserFilterSuccess: true,
        getUserFilterFailure: false,
        getUserFilterError: null,
        getMonthlyUsers: action.payload,
      };
    case USER.GET_USER_FILTER_FAILURE:
      return {
        ...state,
        getUserFilterLoading: false,
        getUserFilterSuccess: false,
        getUserFilterFailure: true,
        // getMonthlyUsers: action.payload,
      };

    case USER.GET_BLOG_DATA_FILTER_LOADING:
      return {
        ...state,
        getBlogDataFilterLoading: true,
        getBlogDataFilterSuccess: false,
        getBlogDataFilterFailure: false,
        getBlogDataFilterError: null,
        // getMonthlyUsers: [],
      };
    case USER.GET_BLOG_DATA_FILTER_SUCCESS:
      return {
        ...state,
        getBlogDataFilterLoading: false,
        getBlogDataFilterSuccess: true,
        getBlogDataFilterFailure: false,
        getBlogDataFilterError: null,
        getMonthlyBlogs: action.payload,
      };
    case USER.GET_BLOG_DATA_FILTER_FAILURE:
      return {
        ...state,
        getBlogDataFilterLoading: false,
        getBlogDataFilterSuccess: false,
        getBlogDataFilterFailure: true,
        // getMonthlyUsers: action.payload,
      };

    case USER.UPDATE_BLOG_LOADING:
      return {
        ...state,
        updateBlogLoadingLoading: true,
        updateBlogLoadingSuccess: false,
        updateBlogLoadingFailure: false,
        updateBlogLoadingError: null,
      };
    case USER.UPDATE_BLOG_SUCCESS:
      let updatedBlogHistory = [...state.blogPostData];
      for (let i = 0; i < updatedBlogHistory.length; i++) {
        if (updatedBlogHistory[i].id === action.payload.blogId) {
          updatedBlogHistory[i].description = action.payload.desc;
          break;
        }
      }

      return {
        ...state,
        updateBlogLoadingLoading: false,
        updateBlogLoadingSuccess: true,
        updateBlogLoadingFailure: false,
        updateBlogLoadingError: null,
        blogPostData: updatedBlogHistory,
      };
    case USER.UPDATE_BLOG_FAILURE:
      return {
        ...state,
        updateBlogLoadingLoading: false,
        updateBlogLoadingSuccess: false,
        updateBlogLoadingFailure: true,
        updateBlogLoadingError: action.payload,
      };
      case USER.SET_SESSION_EXPIRE_STATE:
        return {
          ...state,
          sessionExpire: action.payload,
        };
    
    default:
      return state;
  }
};
