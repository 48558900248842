/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable import/prefer-default-export */

import { Footer } from "./Footer";
import { Header } from "./Header";
import Sidebar from "./SideBar";
export function ParentComponentWithSideBar({ children, ...rest }) {
  return (
    <>
      <div className="main-wrapper mb-5 pb-5">
        <Header />
        <Sidebar />
        <div className="page-wrapper mb-5">
          <div
            className="container-fluid pb-4"
            style={{
              marginTop: "78px",
            }}
          >
            {children}
          </div>
        </div>
      </div>
      <div className="">
        <Footer />
      </div>
    </>
  );
}
